import { BackendService } from './../backend.service';
import { AutocompleteService } from './autocomplete.service';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Subject } from 'rxjs/Subject';
import { CompleterItem, CompleterData } from 'ng2-completer';

@Injectable()
export class GeonameAutocompleteService extends AutocompleteService {

    constructor(private h: Http, private b: BackendService) {
        super(h, b, 'geoname');
    }
}
