import { BackendService } from './../../services/backend.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-new-volume-dialog',
  templateUrl: './new-volume-dialog.component.html',
  styleUrls: ['./new-volume-dialog.component.scss']
})
export class NewVolumeDialogComponent implements OnInit {

  inProgress = false;
  parentPid: string;
  policy: string;
  value1: string;
  value2: string;
  value1label: string;
  value2label: string;
  model: string;

  mode: string;
  item;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private backendService: BackendService,
              private dialogRef: MatDialogRef<NewVolumeDialogComponent>) {

      this.parentPid = data['parentPid'];
      this.policy = data['policy'];
      this.model = data['model'];
      this.item = data['item'];
      if (this.item) {
        this.value1 = this.item.subtitle;
        this.value2 = this.item.title;
        this.policy = 'policy:' + this.item.policy;
        this.mode = 'edit';
      } else {
        this.mode = 'new';
      }
  }

  ngOnInit() {
  }


  onApprove() {
    if (this.mode == 'new') {
      this.createVolume();
    } else if (this.mode == 'edit') {
      this.updateVolume();
    }
  }


  private createVolume() {
    this.inProgress = true;
    let r: Observable<any>;
    if (this.model == 'volume') {
      r = this.backendService.createVolume(this.parentPid, this.policy, this.value1, this.value2)
    } else if (this.model == 'issue') {
      r = this.backendService.createIssue(this.parentPid, this.policy, this.value1, this.value2)
    } else {
      return;
    }
    r.subscribe(
      response => {
        this.inProgress = false;
        this.dialogRef.close({
          item: {
            title: this.value2,
            subtitle: this.value1,
            policy: this.policy,
            pid: response['pid'],
            type: this.model
          }
        });
      },
      error => {
        this.inProgress = false;
        console.log('error', error);
      }
    );
  }



  private updateVolume() {
    this.inProgress = true;
    let r: Observable<any>;
    if (this.model == 'volume') {
      r = this.backendService.updateVolume(this.item.pid, this.policy, this.value1, this.value2)
    } else if (this.model == 'issue') {
      r = this.backendService.updateIssue(this.item.pid, this.policy, this.value1, this.value2)
    } else {
      return;
    }
    r.subscribe(
      response => {
        this.inProgress = false;
        this.dialogRef.close({
          item: {
            title: this.value2,
            subtitle: this.value1
          }
        });
      },
      error => {
        this.inProgress = false;
        console.log('error', error);
      }
    );
  }

}
