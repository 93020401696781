import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { Collection } from './../../model/collection.model';
import { Translator } from 'angular-translator';
import { CollectionsService } from './../../services/collections.service';
import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-editor-collection',
  templateUrl: './editor-collection.component.html',
  styleUrls: ['./editor-collection.component.scss']
})
export class EditorCollectionComponent implements OnInit, OnDestroy {
  @Input() document: Document;
  collections: Collection[];
  locTitle = 'nameEn';

  subscription: Subscription;

  constructor(private collectionsService: CollectionsService,
              private translator: Translator) {}

  ngOnInit() {
    this.setLocTitle();
    this.translator.languageChanged.subscribe((lang) => {
      this.setLocTitle();
    });
    this.subscription = this.collectionsService.getCollections().subscribe(
      result => {
        this.collections = result;
      }
    );
    this.collectionsService.fetchCollections();
  }


  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }


  isInCollection(collection: Collection) {
    return this.document.collections.indexOf(collection.pid) >= 0;
  }

  toggleInCollection(collection: Collection) {
    const index = this.document.collections.indexOf(collection.pid);
    if (index < 0) {
      this.document.collections.push(collection.pid);
    } else {
      this.document.collections.splice(index, 1);
    }
  }


  private setLocTitle() {
    if (this.translator.language === 'cs') {
      this.locTitle = 'nameCs';
    } else {
      this.locTitle = 'nameEn';
    }
  }

}
