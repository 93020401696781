import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PeriodicalItem } from 'app/model/periodicalitem.model';
import { BackendService } from 'app/services/backend.service';
import { KrameriusService } from 'app/services/kramerius.service';


@Component({
  templateUrl: './move-dialog.component.html',
  styleUrls: ['./move-dialog.component.scss']
})
export class MoveDialogComponent implements OnInit {

  state = 0;
  items: PeriodicalItem[];
  inProgress: boolean;
  currentItem: PeriodicalItem;

  selectedItem: PeriodicalItem;

  @ViewChild('itemsList') itemsListEl: ElementRef;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private krameriusApi: KrameriusService,
              private backendService: BackendService,
              private dialogRef: MatDialogRef<MoveDialogComponent>) {
        dialogRef.disableClose = true;
  }


  ngOnInit() {
    this.state = 0;
    this.inProgress = false;
    this.items = [];
    this.krameriusApi.getChildren(this.data.parent).subscribe((children: any[]) => {
      for (let child of children) {
        const item = new PeriodicalItem(child);
        if (item.type == this.data.model) {
          this.items.push(item);
          if (item.pid == this.data.current) {
            this.currentItem = item;
          }
        }
      }
      this.items.sort((a: PeriodicalItem, b: PeriodicalItem) => {
        if (a.sortIndex == b.sortIndex) {
          return a.sortIndex2 - b.sortIndex2;
        }
        return a.sortIndex - b.sortIndex;
      });
      const currentIdx = this.items.indexOf(this.currentItem);
      if (currentIdx > -1) {
        setTimeout(() => {
          if (this.itemsListEl) {
            const el = this.itemsListEl.nativeElement.children[currentIdx];
            el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
          }
        }, 600);
      }
    });

  }

  onSelect(item) {
    if (this.inProgress || item == this.currentItem) {
      return;
    }
    this.selectedItem = item;
  }

  onMove() {
    if (!this.selectedItem) {
      return;
    }
    this.inProgress = true;
    if (this.data.model == 'volume') {
      this.backendService.moveIssues(this.data.pids, this.data.current, this.selectedItem.pid).subscribe(() => {
        this.inProgress = false;
        this.state = 1;
      });
    } else if (this.data.model == 'issue') {
      this.backendService.movePages(this.data.pids, this.data.current, this.selectedItem.pid).subscribe(() => {
        this.inProgress = false;
        this.state = 1;
      });
    }
  }


}
