import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';

import { AppError } from '../common/errors/app-error';
import { NotFoundError } from '../common/errors/not-found-error';
import { Connector } from '../utils/connector.model';

@Injectable()
export class KrameriusService {

  private static STREAM_DC = 'DC';
  private static STREAM_MODS = 'BIBLIO_MODS';

  private BASE_URL = Connector.KRAMERIUS_URL;

  constructor(private http: Http) { }

  getDc(uuid: string) {
    const url = this.getItemStreamUrl(uuid, KrameriusService.STREAM_DC);
    return this.http.get(url)
      .map(response => response['_body'])
      .catch(this.handleError);
  }

  getMods(uuid: string) {
    const url = this.getItemStreamUrl(uuid, KrameriusService.STREAM_MODS);
    return this.http.get(url)
      .map(response => response['_body'])
      .catch(this.handleError);
  }

  getItem(uuid: string) {
    const url = this.getItemUrl(uuid);
    return this.http.get(url)
      .map(response => response.json())
      .catch(this.handleError);
  }

  getChildren(uuid: string) {
    const url = this.getItemUrl(uuid) + '/children';
    return this.http.get(url)
      .map(response => response.json())
      .catch(this.handleError);
  }

  getCollections() {
    const url = this.getApiUrl() + '/vc';
    return this.http.get(url)
      .map(response => response.json())
      .catch(this.handleError);
  }

  getThumbUrl(uuid: string) {
    return this.getItemUrl(uuid) + '/thumb';
  }

  getFullImageUrl(uuid: string) {
    return `${this.BASE_URL}/search/img?pid=${uuid}&stream=IMG_FULL&action=GETRAW`;
  }

  getSolrResult(query: string) {
    const url = this.getApiUrl() + '/search?' + query;
    return this.http.get(url)
      .map(response => response.json())
      .catch(this.handleError);
  }

  private getItemStreamUrl(uuid: string, stream: string) {
    return this.getItemUrl(uuid) + '/streams/' + stream;
  }

  private getItemUrl(uuid: string): string {
    return this.getApiUrl() +  '/item/' + uuid;
  }

  private getApiUrl(): string {
    return this.BASE_URL + '/search/api/v5.0';
  }

  private handleError(error: Response) {
    if (error.status === 404) {
      return Observable.throw(new NotFoundError());
    }
    return Observable.throw(new AppError(error));
  }




}
