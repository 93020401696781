import { Translator } from 'angular-translator';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-publish-document-dialog',
  templateUrl: './publish-document-dialog.component.html',
  styleUrls: ['./publish-document-dialog.component.scss']
})
export class PublishDocumentDialogComponent implements OnInit {

  model: string;
  modelCodes = [];
  monographModels = ['graphic', 'monograph', 'map', 'archive', 'manuscript'];
  periodicalModels = ['periodical'];

  modelNames = {};


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
          public translator: Translator) {
    this.model = data['model'];
    if (this.model == 'periodical') {
      this.modelCodes = this.periodicalModels;
    } else {
      this.modelCodes = this.monographModels;
    }
    this.translateModels();
    translator.languageChanged.subscribe(() => this.translateModels());
  }

  ngOnInit() {
  }

  private translateModels() {
    this.translator.waitForTranslation().then(() => {
      this.modelNames = [];
      for (const code of this.modelCodes) {
        this.modelNames[code] = this.translator.instant('model.' + code);
      }
    });
  }

}
