import { getTestBed } from '@angular/core/testing';
import { KrameriusService } from './../services/kramerius.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drafts',
  templateUrl: './drafts.component.html',
  styleUrls: ['./drafts.component.scss']
})
export class DraftsComponent implements OnInit {

  drafts: any[];

  constructor(private krameriusService: KrameriusService) { }

  ngOnInit() {
    this.krameriusService.getSolrResult('fl=PID,dc.title&q=fedora.model:draft&sort=created_date%20desc&rows=200').subscribe(
      result => {
        this.drafts = result['response']['docs'];
      }
    );
  }



}
