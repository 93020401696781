import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Note extends Item {

    public note;
    public attrs;

    static getSelector() {
        return 'note';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['_']) {
            this.modsElement['_'] = '';
        }
        if (!this.modsElement['$']) {
            this.modsElement['$'] = {
                type: ''
            };
        }
        if (!this.modsElement['$']['type']) {
            this.modsElement['$']['type'] = '';
        }
        this.attrs = this.modsElement['$'];
        this.note = this.modsElement;
    }


    public toDC() {
        if (this.note['_']) {
            return Utils.dcEl('description', this.note['_']);
        } else {
            return '';
        }
    }

}
