import { Volume } from './../../model/volume.model';
import { Component, OnInit, Input } from '@angular/core';
import { BackendService } from 'app/services/backend.service';

@Component({
  selector: 'app-editor-volume',
  templateUrl: './editor-volume.component.html',
  styleUrls: ['./editor-volume.component.css']
})
export class EditorVolumeComponent implements OnInit {
  @Input() item: Volume;

  constructor(public backendService: BackendService) {
  }

  ngOnInit() {
  }

}
