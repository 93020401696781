import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-document-save-success-dialog',
  templateUrl: './document-save-success-dialog.component.html',
  styleUrls: ['./document-save-success-dialog.component.scss']
})
export class DocumentSaveSuccessDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
