import { Subject } from 'rxjs/Subject';
import { AppError } from './../common/errors/app-error';
import { Observable } from 'rxjs/Observable';
import { KrameriusService } from './kramerius.service';
import { Collection } from 'app/model/collection.model';
import { Injectable } from '@angular/core';


@Injectable()
export class CollectionsService {
  private subject = new Subject<Collection[]>();

  constructor(private krameriusService: KrameriusService) { }

  fetchCollections() {
    const solrQ = 'q=*:*%20AND%20(fedora.model:monograph%20OR%20fedora.model:periodical%20OR%20fedora.model:soundrecording%20OR%20fedora.model:map%20OR%20fedora.model:graphic%20OR%20fedora.model:sheetmusic%20OR%20fedora.model:archive%20OR%20fedora.model:manuscript)'
                + '&facet=true&facet.field=collection&facet.mincount=1&facet.sort=count&facet.limit=1000&facet.offset=0&rows=0';
    const solrRequest = this.krameriusService.getSolrResult(solrQ);
    const vcRequest = this.krameriusService.getCollections();

    Observable.forkJoin([vcRequest, solrRequest]).subscribe(
      results => {
        const vc = results[0];
        const solr = results[1];
        this.populateCollections(vc, solr);
      },
      (error: AppError) => {
        console.log('fetchCollections failed', error.originalError);
      }
    );
  }


  getCollections(): Observable<Collection[]> {
    return this.subject.asObservable();
  }


  private populateCollections(vc, solr) {
    const cache = {};
    const collections: Collection[] = [];
    for (const col of vc) {
      const collection = new Collection();
      if (col.descs) {
        collection.nameCs = col.descs.cs;
        collection.nameEn = col.descs.en;
        collection.count = 0;
      }
      collection.pid = col.pid;
      cache[collection.pid] = collection;
      collections.push(collection);
    }
    const cols = solr['facet_counts']['facet_fields']['collection'];
    for (let i = 0;  i < cols.length; i ++) {
      const collection = cache[cols[i]];
      if (collection) {
        collection.count = cols[i + 1];
      }
    }
    this.subject.next(collections);
  }

}
