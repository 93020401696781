  import { BackendService } from './../services/backend.service';
  import { Component, OnInit, EventEmitter, Output } from '@angular/core';
  import { MatDialog } from '@angular/material';
  import { Translator } from 'angular-translator';
  import { LoginDialogComponent } from '../dialogs/login-dialog/login-dialog.component';
  import Utils from '../utils';
  import { UpdaterService } from '../services/updater.service';

  @Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
  })
  export class NavbarComponent implements OnInit {

    constructor(public translator: Translator,
                private dialog: MatDialog,
                private updater: UpdaterService,

                public backendService: BackendService) {
    }

    ngOnInit() {
    }

    onLanguageChanged(lang: string) {
      localStorage.setItem('lang', lang);
      this.translator.language = lang;
    }


    login() {
      this.dialog.open(LoginDialogComponent)
          .afterClosed()
          .subscribe(result => console.log(result));
    }

    // updateAllDocuments() {
    //   this.updater.run()
    // }


    // newDraft() {
    //     const dialogRef = this.dialog.open(NewDocumentDialogComponent);
    //     dialogRef.afterClosed().subscribe(result=> {
    //       if (result && result !== 'close' && result['pid']) {
    //         const uuid = result['pid'];
    //         this.router.navigate(['/documents', uuid]);
    //       }
    //     });
    //   }



  }
