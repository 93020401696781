import { Volume } from './../../model/volume.model';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Document } from './../../model/document.model';
import { MoveDialogComponent } from 'app/dialogs/move-dialog/move-dialog.component';
import { BackendService } from 'app/services/backend.service';

@Component({
  selector: 'app-editor-issue',
  templateUrl: './editor-issue.component.html',
  styleUrls: ['./editor-issue.component.css']
})
export class EditorIssueComponent implements OnInit {
  @Input() item: Volume;
  @Input() document: Document;

  constructor(private dialog: MatDialog, public backendService: BackendService) {
  }

  ngOnInit() {
  }

  onMove() {
    const path = this.document.pidPath.split('/');
    // console.log('path', path);
    const dialogRef = this.dialog.open(MoveDialogComponent, {
      data: { pids: [this.document.uuid], current: path[1], parent: path[0], model: 'volume' }
    });
  }

}
