import { environment } from './../environments/environment';
import { Router, NavigationEnd } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Translator } from 'angular-translator';
import { BackendService } from './services/backend.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  constructor(
    private translator: Translator, 
    private router: Router, 
    private backend: BackendService) {
    const lang = localStorage.getItem('lang');
    if (lang) {
      this.translator.language = lang;
    }
    this.backend.update_token().subscribe(
      () => {},
      (error) => {
        console.log('err', error);
        if (error && error.status === 401) {
          this.backend.logout();
        }
      }
    );
    if (environment.production) {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          (<any>window).ga('set', 'page', event.urlAfterRedirects);
          (<any>window).ga('send', 'pageview');
        }
      });
    }
  }

  ngOnInit() {
  }

}
