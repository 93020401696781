import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';

import { trigger, state, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-editor-title',
  templateUrl: './editor-title.component.html',
  styleUrls: ['./editor-title.component.css']
})
export class EditorTitleComponent implements OnInit {
  @Input() field: Field;

  types: string[] = ['', 'abbreviated', 'translated', 'alternative', 'uniform'];

  constructor() { }

  ngOnInit() {
  }



}
