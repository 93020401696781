import { AppError } from './../../common/errors/app-error';
import { BackendService } from './../../services/backend.service';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';


@Component({
  selector: 'app-remove-document-dialog',
  templateUrl: './remove-document-dialog.component.html',
  styleUrls: ['./remove-document-dialog.component.scss']
})
export class RemoveDocumentDialogComponent implements OnInit {

  state = 0;
  pid: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private backendService: BackendService) {
    this.pid = data['pid'];
  }

  ngOnInit() {
  }


  onConfirm() {
    this.state = 1;
    this.backendService.deleteDocument(this.pid)
      .subscribe(
        result => {
         this.state = 2;
        },
        (error: AppError) => {
          this.state = 3;
        }
      );
  }


}
