import { Item } from 'app/model/item.model';

export class Part extends Item {

    public attrs;
    public number;

    static getSelector() {
        return 'part';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['$']) {
            this.modsElement['$'] = { 'order': ''};
        }
        if (!this.modsElement['$']['order']) {
            this.modsElement['$']['order'] = '';
        }
        if (!this.modsElement['detail']) {
            this.modsElement['detail'] = [{}];
        }
        if (!this.modsElement['detail'][0]['number']) {
            this.modsElement['detail'][0]['number'] = [
                { '_': '' }
            ];
        }
        if (!this.modsElement['detail'][0]['number'][0]) {
            this.modsElement['detail'][0]['number'][0] = { '_': '' };
        }
        if (!this.modsElement['detail'][0]['number'][0]['_']) {
            this.modsElement['detail'][0]['number'][0]['_'] =  '';
        }
        this.number = this.modsElement['detail'][0]['number'][0];
        this.attrs = this.modsElement['$'];
    }

    public toDC() {
       return '';
    }

}
