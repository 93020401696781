import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Role extends Item {

    public role;

    static getSelector() {
        return 'role';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['roleTerm']) {
            const attrs = {'type': 'code', 'authority': 'marcrelator'};
            this.modsElement['roleTerm'] = [Utils.createTextElement('', attrs)];
        }
        this.role = this.modsElement['roleTerm'][0];
    }


    public toDC() {
        return '';
    }

}
