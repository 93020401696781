import { BackendService } from './../backend.service';
import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { Subject } from 'rxjs/Subject';
import { CompleterItem, CompleterData } from 'ng2-completer';

@Injectable()
export class AutocompleteService extends Subject<CompleterItem[]> implements CompleterData {

    private field: string;

    constructor(private http: Http, private backendService: BackendService, field: string) {
        super();
        this.field = field;
    }
    public search(term: string): void {
      if (!term) {
        term = '';
      }
      const url = this.backendService.getAutocompleteUrl(this.field, term.toLowerCase());
      this.http.get(url)
            .map((res: Response) => {
                const json = res.json();
                const items = [];
                const cache = {};
                for (const item of json) {
                    const text = item[0];
                    if (cache[text]) {
                        continue;
                    }
                    let index = text.toLowerCase().indexOf(term.toLowerCase());
                    if (index < 0) {
                      index = 1000 + text.length;
                    }
                    items.push({index: index, item: item});
                    cache[text]  = true;
                }
                items.sort(function(a, b) {
                    if (a.index < b.index) {
                        return -1;
                    }
                    if (a.index > b.index) {
                        return 1;
                    }
                    return 0;
                });
                const matches: CompleterItem[] = [];
                for (const item of items) {
                    matches.push(this.convertToItem(item.item));
                }
                this.next(matches);
            })
            .subscribe();
    }

    public cancel() {
        // Handle cancel
    }

    public convertToItem(item: any): CompleterItem | null {
        if (!item) {
            return null;
        }
        // data will be string if an initial value is set
        return {
            title: item[0],
            data: JSON.parse(item[1]),
            // image : this.krameriusApiService.getThumbUrl(data.PID),
            originalObject: item[0]
        } as CompleterItem;
    }
}
