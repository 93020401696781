import { Component, OnInit } from '@angular/core';
import { Save } from 'app/document/document.component';


@Component({
  selector: 'app-document-save-dialog',
  templateUrl: './document-save-dialog.component.html',
  styleUrls: ['./document-save-dialog.component.scss']
})
export class DocumentSaveDialogComponent implements OnInit {

  state = 0;
  title: string;
  message: string;

  constructor() {
  }

  ngOnInit() {
  }


  public changeState(type: Save) {
    this.state = 1;
    if (type === Save.UPDATE) {
      this.title = 'title';
      this.message = 'message1';
    } else if (type === Save.PUBLISH) {
      this.title = 'title_publish';
      this.message = 'message1_publish';
    } else if (type === Save.UNPUBLISH) {
      this.title = 'title_unpublish';
      this.message = 'message1_unpublish';
    }
  }


}
