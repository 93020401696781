import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class PLocation extends Item {

    public physicalLocation;
    public shelfLocator;
    public url;

    static getSelector() {
        return 'location';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['physicalLocation']) {
            this.modsElement['physicalLocation'] = Utils.createEmptyField();
        }
        if (!this.modsElement['shelfLocator']) {
            this.modsElement['shelfLocator'] = Utils.createEmptyField();
        }
        if (!this.modsElement['url']) {
            this.modsElement['url'] = Utils.createEmptyField();
        }
        this.physicalLocation = this.modsElement['physicalLocation'][0];
        this.shelfLocator = this.modsElement['shelfLocator'][0];
        this.url = this.modsElement['url'][0];
    }

    public toDC() {
        return '';
    }

}
