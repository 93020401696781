import { BackendService } from '../../services/backend.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-remove-volume-dialog',
  templateUrl: './remove-volume-dialog.component.html',
  styleUrls: ['./remove-volume-dialog.component.scss']
})
export class RemoveVolumeDialogComponent implements OnInit {

  inProgress = false;
  model: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private backendService: BackendService,
              private dialogRef: MatDialogRef<RemoveVolumeDialogComponent>) {
    this.model = data['model'];
  }

  ngOnInit() {
  
  }

  onRemove() {
    this.inProgress = true;
    let r: Observable<any>;
    if (this.model == 'volume') {
      r = this.backendService.removeVolume(this.data['uuid']);
    } else if (this.model == 'issue') {
      r = this.backendService.removeIssue(this.data['uuid']);
    } else {
      return;
    }
    r.subscribe(
      response => {
        this.inProgress = false;
        this.dialogRef.close({ success: true });
      },
      error => {
        this.inProgress = false;
        console.log('error', error);
      }
    );
  }

}
