import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-part',
  templateUrl: './editor-part.component.html',
  styleUrls: ['./editor-part.component.css']
})
export class EditorPartComponent implements OnInit {
  @Input() field: Field;

  constructor() {}

  ngOnInit() {
  }


}
