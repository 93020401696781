import { UpdaterService } from './services/updater.service';
import { EditorPartComponent } from './editor/editor-part/editor-part.component';
import { PublishDocumentDialogComponent } from './dialogs/publish-document-dialog/publish-document-dialog.component';
import { UnpublishDocumentDialogComponent } from './dialogs/unpublish-document-dialog/unpublish-document-dialog.component';
import { SimpleDialogComponent } from './dialogs/simple-dialog/simple-dialog.component';
import { DraftsComponent } from './drafts/drafts.component';
import { PageImageDialogComponent } from './editor/editor-pages/page-image-dialog/page-image-dialog.component';
import { EditorCollectionComponent } from './editor/editor-collection/editor-collection.component';
import { NewCollectionDialogComponent } from './dialogs/new-collection-dialog/new-collection-dialog.component';
import { CollectionsComponent } from './collections/collections.component';
import { KeywordAutocompleteService } from './services/autocomplete/keyword-autocomplete.service';
import { GeonameAutocompleteService } from './services/autocomplete/geoname-autocomplete.service';
import { LocationAutocompleteService } from './services/autocomplete/location-autocomplete.service';
import { AuthorAutocompleteService } from './services/autocomplete/author-autocomplete.service';
import { PublisherAutocompleteService } from './services/autocomplete/publisher-autocomplete.service';
import { PlaceAutocompleteService } from './services/autocomplete/place-autocomplete.service';
import { Ng2CompleterModule } from './completer';
import { MatComponentsModule } from './mat-components/mat-components.module';
import { DocumentSaveSuccessDialogComponent } from './dialogs/document-save-success-dialog/document-save-success-dialog.component';
import { DocumentSaveDialogComponent } from './dialogs/document-save-dialog/document-save-dialog.component';
import { NotLoggedInDialogComponent } from './dialogs/not-logged-in-dialog/not-logged-in-dialog.component';
import { BackendService } from './services/backend.service';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';

import { AppComponent } from './app.component';

import { EditorTitleComponent } from './editor/editor-title/editor-title.component';
import { EditorModsComponent } from './editor/editor-mods/editor-mods.component';
import { EditorJsonComponent } from './editor/editor-json/editor-json.component';
import { EditorLanguageComponent } from './editor/editor-language/editor-language.component';
import { EditorLocationComponent } from './editor/editor-location/editor-location.component';
import { EditorIdentifierComponent } from './editor/editor-identifier/editor-identifier.component';

import { EditorDcComponent } from './editor/editor-dc/editor-dc.component';

import { TranslatorModule } from 'angular-translator';
import { EditorPublisherComponent } from './editor/editor-publisher/editor-publisher.component';

import { NavbarComponent } from './navbar/navbar.component';

// import { FlexLayoutModule } from '@angular/flex-layout';
import { EditorChildrenComponent } from './editor/editor-children/editor-children.component';
import { LoginDialogComponent } from './dialogs/login-dialog/login-dialog.component';

import { HomeComponent } from './home/home.component';
import { DocumentComponent } from './document/document.component';

import { KrameriusService } from './services/kramerius.service';
import { EditorCategoryComponent } from './editor/editor-category/editor-category.component';
import { EditorKeywordComponent } from './editor/editor-keyword/editor-keyword.component';
import { EditorAuthorComponent } from './editor/editor-author/editor-author.component';
import { EditorNoteComponent } from './editor/editor-note/editor-note.component';
import { EditorPhysicalComponent } from './editor/editor-physical/editor-physical.component';
import { EditorVolumeComponent } from './editor/editor-volume/editor-volume.component';
import { EditorIssueComponent } from './editor/editor-issue/editor-issue.component';
// import { NoConflictStyleCompatibilityMode } from '@angular/material';
import { CollectionsService } from 'app/services/collections.service';
import { EditorPagesComponent } from 'app/editor/editor-pages/editor-pages.component';
import { EditorPageComponent } from 'app/editor/editor-pages/editor-page/editor-page.component';
// import { DndModule } from 'ng2-dnd';
import { RemoveDocumentDialogComponent } from 'app/dialogs/remove-document-dialog/remove-document-dialog.component';
import { NewDraftComponent } from 'app/drafts/new-draft/new-draft.component';
import { CreatePagesDialogComponent } from 'app/dialogs/ceate-pages-dialog/create-pages-dialog.component';
import { EditorsRelatedItemComponent } from './editor/editor-relateditem/editor-relateditem.component';
import { EditorPolicyComponent } from './editor/editor-policy/editor-policy.component';
import { NewVolumeDialogComponent } from './dialogs/new-volume-dialog/new-volume-dialog.component';
import { RemoveVolumeDialogComponent } from './dialogs/remove-volume-dialog/remove-volume-dialog.component';
import { MoveDialogComponent } from './dialogs/move-dialog/move-dialog.component';
import { PageRotationDialogComponent } from './editor/editor-pages/page-rotation-dialog/page-rotation-dialog.component';
import { RenumberIssuesDialogComponent } from './dialogs/renumber-issues-dialog/renumber-issues-dialog.component';
import { EditCoordinatesDialogComponent } from './dialogs/edit-coordinates-dialog/edit-coordinates-dialog.component';


const ROUTES = [
  { path: '', redirectTo: '/search', pathMatch: 'full' },
  { path: 'search', component: HomeComponent },
  { path: 'drafts/new', component: NewDraftComponent },
  { path: 'drafts', component: DraftsComponent },
  { path: 'documents/:uuid', component: DocumentComponent },
  { path: 'collections', component: CollectionsComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    EditorTitleComponent,
    EditorModsComponent,
    EditorJsonComponent,
    EditorLanguageComponent,
    EditorLocationComponent,
    EditorIdentifierComponent,
    EditorDcComponent,
    EditorPublisherComponent,
    EditorPhysicalComponent,
    NavbarComponent,
    EditorChildrenComponent,
    LoginDialogComponent,
    NotLoggedInDialogComponent,
    DocumentSaveDialogComponent,
    DocumentSaveSuccessDialogComponent,
    NewCollectionDialogComponent,
    HomeComponent,
    DocumentComponent,
    EditorPagesComponent,
    EditorPageComponent,
    EditorCategoryComponent,
    EditorKeywordComponent,
    EditorAuthorComponent,
    EditorNoteComponent,
    EditorVolumeComponent,
    EditorIssueComponent,
    EditorPartComponent,
    EditorsRelatedItemComponent,
    CollectionsComponent,
    EditorCollectionComponent,
    PageImageDialogComponent,
    DraftsComponent,
    RemoveDocumentDialogComponent,
    NewDraftComponent,
    SimpleDialogComponent,
    UnpublishDocumentDialogComponent,
    PublishDocumentDialogComponent,
    CreatePagesDialogComponent,
    EditorPolicyComponent,
    NewVolumeDialogComponent,
    RemoveVolumeDialogComponent,
    MoveDialogComponent,
    PageRotationDialogComponent,
    RenumberIssuesDialogComponent,
    EditCoordinatesDialogComponent
  ],
  entryComponents: [
    LoginDialogComponent,
    NotLoggedInDialogComponent,
    DocumentSaveDialogComponent,
    DocumentSaveSuccessDialogComponent,
    NewCollectionDialogComponent,
    PageImageDialogComponent,
    RemoveDocumentDialogComponent,
    SimpleDialogComponent,
    UnpublishDocumentDialogComponent,
    PublishDocumentDialogComponent,
    CreatePagesDialogComponent,
    NewVolumeDialogComponent,
    RemoveVolumeDialogComponent,
    MoveDialogComponent,
    PageRotationDialogComponent,
    RenumberIssuesDialogComponent,
    EditCoordinatesDialogComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MatComponentsModule,
    Ng2CompleterModule,
    // NoConflictStyleCompatibilityMode,
    HttpModule,
    // DndModule.forRoot(),
    RouterModule.forRoot(ROUTES),
    TranslatorModule.forRoot({
      providedLanguages: ['en', 'cs'],
      defaultLanguage: 'en'
    })
  ],
  providers: [
    BackendService,
    KrameriusService,
    CollectionsService,
    UpdaterService,
    PlaceAutocompleteService,
    PublisherAutocompleteService,
    AuthorAutocompleteService,
    LocationAutocompleteService,
    GeonameAutocompleteService,
    KeywordAutocompleteService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
