import { PublisherAutocompleteService } from './../../services/autocomplete/publisher-autocomplete.service';
import { PlaceAutocompleteService } from './../../services/autocomplete/place-autocomplete.service';
import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-publisher',
  templateUrl: './editor-publisher.component.html',
  styleUrls: ['./editor-publisher.component.scss']
})
export class EditorPublisherComponent implements OnInit {
  @Input() field: Field;

  eventTypes: string[] = ['', 'publication', 'digitization', 'production'];

  constructor(private placeAutocompleteService: PlaceAutocompleteService,
    private publisherAutocompleteService: PublisherAutocompleteService) {
  }

  ngOnInit() {

  }


}
