
export class Collection {

    public pid: string;
    public nameCs: string;
    public nameEn: string;
    public count: number;
    public loading = false;


    constructor() {
    }

}
