/**
 * 
 * Usage:
 * 
 * const data_in_internal_format_or_error = parse(data_in_input_format)
 * if (data_in_internal_format_or_error.ok) {
 *     const data_in_output_format = toOutputFormat(data_in_internal_format_or_error.value?.coords)
 *     console.log('result: ' + data_in_output_format);
 * } else {
 *     console.log('parsing error: ' + data_in_internal_format_or_error.error?.code + ': ' + data_in_internal_format_or_error.error?.message)
 * }
 * 
 * Input format is either:
 * 1. Google maps: 48.142222, 17.15 
 * 2. Geohack 1: 48.142222°, 17.1°
 * 3. Geohack 2: 50° 5′ 25.98″ N, 14° 23′ 59.1″ E
 * 4. Bounding box: E 15°50'00"--E 16°50'00"/N 49°30'00"--N 48°30'00"
 * 
 * Internal format is either:
 * 1. point in float degrees (latitude, longitude), for example: 33.123°,58.456°
 * 2. two points (top-left;bottom-right) defining bounding box, for example: 33.123°,58.456°;34.123°,59.456°
 * 
 * Output format is always a bounding box:
 * 1. for single point 48°,17° the output format is (E 14°00'00"--E 14°00'00"/N 17°00'00"--N 17°00'00")
 * 2. for bounding box 35°,-5°;-7°,123° the output format is (W 5°00'00"--E 123°00'00"/N 35°00'00"--S 7°00'00")
 * 
 */

/**
 * Parses input in any of supported Input formats and returns data in Internal format 
 * @param coords coords/bbox in Input format
 * @returns 
 */
 export function parse(coords: string): Result {

    //Google maps: 48.142222, 17.15 
    const googleMapsRegexp = /^((\-?|\+?)?\d+(\.\d+)?),\s*((\-?|\+?)?\d+(\.\d+)?)$/gi;
    if (googleMapsRegexp.test(coords)) {
        let vals = coords.split(',').map(x => parseFloat(x));
        if (vals[0] < -90 || vals[0] > 90) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'latitude must be in <-90,90>' } };
        }
        if (vals[1] < -180 || vals[1] > 180) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'longitude must be in <-180,180>' } };
        }
        return { ok: true, value: { coords: '' + vals[0] + '°,' + vals[1] + '°', format: InputFormat.GOOGLE_MAPS } };
    }

    //Geohack 1: 48.142222°, 17.1°
    const geohack1Regexp = /^((\-?|\+?)?\d+(\.\d+)?)°,\s*((\-?|\+?)?\d+(\.\d+)?°)$/gi;
    if (geohack1Regexp.test(coords)) {
        let vals = coords.split(',').map(x => x.slice(0, -1)).map(x => parseFloat(x));
        if (vals[0] < -90 || vals[0] > 90) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'latitude must be in <-90,90>' } };
        }
        if (vals[1] < -180 || vals[1] > 180) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'longitude must be in <-180,180>' } };
        }
        return { ok: true, value: { coords: '' + vals[0] + '°,' + vals[1] + '°', format: InputFormat.GEOHACK_1 } };
    }

    //Geohack 2: 50° 5′ 25.98″ N, 14° 23′ 59.1″ E
    const geohack2Regexp = /^(\d+(\.\d+)?°\s*\d+(\.\d+)?′\s*\d+(\.\d+)?″\s*[NS],\s*\d+(\.\d+)?°\s*\d+(\.\d+)?′\s*\d+(\.\d+)?″\s*[EW])$/gi;
    if (geohack2Regexp.test(coords)) {
        const normalized = coords.replace(/\s/g, "");

        let vals = normalized.split(',');
        let latVals = vals[0].split(/[°′″]/);
        let longVals = vals[1].split(/[°′″]/);

        let lat = +((latVals[3] == 'S' ? -1 : 1) * (+latVals[0] + +latVals[1] / 60 + +latVals[2] / 3600)).toFixed(6);
        let long = +((longVals[3] == 'W' ? -1 : 1) * (+longVals[0] + +longVals[1] / 60 + +longVals[2] / 3600)).toFixed(6);

        if (lat < -90.0 || lat > 90.0) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'latitude must be in <-90,90>' } };
        }
        if (long < -180.0 || long > 180.0) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'longitude must be in <-180,180>' } };
        }
        return { ok: true, value: { coords: '' + lat + '°,' + long + '°', format: InputFormat.GEOHACK_2 } };
    }

    //Bounding box: (E 15°50'00"--E 16°50'00"/N 49°30'00"--N 48°30'00") is converted to (top_left_corner;bottom_right_corner)
    const bboxRegexp = /^\(([EW]\s\d{1,3}°\d{2}'\d{2}"--[EW]\s\d{1,3}°\d{2}'\d{2}"\/[NS]\s\d{1,2}°\d{2}'\d{2}"--[NS]\s\d{1,2}°\d{2}'\d{2}")\)$/gi;
    if (bboxRegexp.test(coords)) {
        const vals = coords.split(/[--\/\(\)]/).filter(x => x != '')
        const lon1 = lonToFloat(vals[0]);
        const lon2 = lonToFloat(vals[1]);
        const lat1 = latToFloat(vals[2]);
        const lat2 = latToFloat(vals[3]);

        if (isParsingError(lon1)) {
            return { ok: false, error: lon1 };
        }
        if (isParsingError(lon2)) {
            return { ok: false, error: lon2 };
        }

        if (isParsingError(lat1)) {
            return { ok: false, error: lat1 };
        }
        if (isParsingError(lat2)) {
            return { ok: false, error: lat2 };
        }
        if (lat1 < lat2) {
            return { ok: false, error: { code: ErrorCode.INCORRECT_VALUE, message: 'latitude of top-left point must be same or bigger then latitude of  bottom-right point' } };
        }

        return { ok: true, value: { coords: lat1 + "°," + lon1 + "°;" + lat2 + "°," + lon2 + "°", format: InputFormat.BOUNDING_BOX } };
    }

    //console.log('unknown format: ' + coords);
    return { ok: false, error: { code: ErrorCode.INVALID_SYNTAX } };
}

/** converts E 15°30'00" -> 15,5 */
function latToFloat(strVal: string): number | ParsingError {
    const vals = strVal.split(/[\s°'"]/).filter(x => x != '')
    //console.log(vals);
    let lat = (+vals[1] + +vals[2] / 60 + +vals[3] / 3600) * (vals[0] == 'N' ? 1 : -1);
    if (lat < -90.0 || lat > 90.0) {
        return { code: ErrorCode.INCORRECT_VALUE, message: 'latitude must be in <-90,90>' };
    }
    return lat;
}

/** converts N 49°30'00" -> 49,5 */
function lonToFloat(strVal: string): number | ParsingError {

    const vals = strVal.split(/[\s°'"]/).filter(x => x != '')
    //console.log(vals);
    let lon = (+vals[1] + +vals[2] / 60 + +vals[3] / 3600) * (vals[0] == 'E' ? 1 : -1);
    if (lon < -180.0 || lon > 180.0) {
        return { code: ErrorCode.INCORRECT_VALUE, message: 'longitude must be in <-180,180>' }
    }
    return lon;
}

/** 
 * Converts 50.09055°,14.39975°  ->  50° 5′ 25.98″ N, 14° 23′ 59.1″ E"
 * @deprecated 
 * */
export function toOldFinalFormat(coords: string): string | undefined {
    const inputFormatRegexp = /^((\-?|\+?)?\d+(\.\d+)?)°,((\-?|\+?)?\d+(\.\d+)?°)$/gi;
    if (!inputFormatRegexp.test(coords)) {
        console.log('unexpected value: ' + coords)
        return undefined;
    }

    const vals = coords.split(',').map(x => x.slice(0, -1)).map(x => parseFloat(x));

    //latitude
    const latFloat = vals[0];
    const latIsNorth = latFloat >= 0.0;
    const latFloatPos = latFloat * (latIsNorth ? 1 : -1);
    const latStrPos = '' + (latFloat * (latIsNorth ? 1 : -1));

    const latDeg = parseInt(latStrPos);//int  // * (latNorth ? 1 : -1);
    const latMinAll = (latFloatPos - latDeg) * 60.0; //float
    const latMin = parseInt('' + latMinAll); //int
    const latSecsAll = + ((latMinAll - latMin) * 60.0).toFixed(6); //float/int, no extra zeroes

    //longitude
    const lonFloat = vals[1];
    const lonIsEast = lonFloat >= 0.0;
    const lonFloatPos = lonFloat * (lonIsEast ? 1 : -1);
    const lonStrPos = '' + (lonFloat * (lonIsEast ? 1 : -1));

    const lonDeg = parseInt(lonStrPos)//int
    const lonMinAll = (lonFloatPos - lonDeg) * 60.0; //float
    const lonMin = parseInt('' + lonMinAll); //int
    const lonSecsAll = + ((lonMinAll - lonMin) * 60.0).toFixed(6); //float/int, no extra zeroes

    return latDeg + "° " + latMin + "′ " + latSecsAll + "″ " + (latIsNorth ? 'N' : 'S') + ", " + lonDeg + "° " + lonMin + "′ " + lonSecsAll + "″ " + (lonIsEast ? 'E' : 'W');
}

/**
 * Converts coords/bbox in Internal format to Output format
 * @param coordsStr coordinates or bounding box in Internal format
 * @returns bounding box in Output format
 */
export function toOutputFormat(coordsStr: string | undefined): string | undefined {
    if (!coordsStr) {
        return;
    }
    const pointRegexp = /^((\-?|\+?)?\d+(\.\d+)?)°,((\-?|\+?)?\d+(\.\d+)?°)$/gi;
    const twoPointsRegexp = /^((\-?|\+?)?\d+(\.\d+)?)°,((\-?|\+?)?\d+(\.\d+)?°;(\-?|\+?)?\d+(\.\d+)?)°,((\-?|\+?)?\d+(\.\d+)?°)$/gi;
    if (pointRegexp.test(coordsStr)) {
        let coords: Coordinates = extractCoordinates(coordsStr);

        return "("
            + (coords.lon.east ? 'E ' : 'W ') + coords.lon.deg + "°" + to2digits(coords.lon.min) + "\'" + to2digits(coords.lon.sec) + "\""
            + "--"
            + (coords.lon.east ? 'E ' : 'W ') + coords.lon.deg + "°" + to2digits(coords.lon.min) + "\'" + to2digits(coords.lon.sec) + "\""
            + "/"
            + (coords.lat.north ? 'N ' : 'S ') + coords.lat.deg + "°" + to2digits(coords.lat.min) + "\'" + to2digits(coords.lat.sec) + "\""
            + "--"
            + (coords.lat.north ? 'N ' : 'S ') + coords.lat.deg + "°" + to2digits(coords.lat.min) + "\'" + to2digits(coords.lat.sec) + "\")"
    }
    if (twoPointsRegexp.test(coordsStr)) {
        const points = coordsStr.split(';');
        let leftTop: Coordinates = extractCoordinates(points[0]);
        let rigthBottom: Coordinates = extractCoordinates(points[1]);
        //console.log(leftTop)

        return "("
            + (leftTop.lon.east ? 'E ' : 'W ') + leftTop.lon.deg + "°" + to2digits(leftTop.lon.min) + "\'" + to2digits(leftTop.lon.sec) + "\""
            + "--"
            + (rigthBottom.lon.east ? 'E ' : 'W ') + rigthBottom.lon.deg + "°" + to2digits(rigthBottom.lon.min) + "\'" + to2digits(rigthBottom.lon.sec) + "\""
            + "/"
            + (leftTop.lat.north ? 'N ' : 'S ') + leftTop.lat.deg + "°" + to2digits(leftTop.lat.min) + "\'" + to2digits(leftTop.lat.sec) + "\""
            + "--"
            + (rigthBottom.lat.north ? 'N ' : 'S ') + rigthBottom.lat.deg + "°" + to2digits(rigthBottom.lat.min) + "\'" + to2digits(rigthBottom.lat.sec) + "\")"
    }
}

/** '51.377499357364066°,-2.359621133927543°' -> (51,22,38) */
function extractCoordinates(coords: string): Coordinates {
    const vals = coords.split(',').map(x => x.slice(0, -1)).map(x => parseFloat(x));

    //latitude
    const latFloat = vals[0];
    const latIsNorth = latFloat >= 0.0;
    const latFloatPos = latFloat * (latIsNorth ? 1 : -1);
    const latStrPos = '' + (latFloat * (latIsNorth ? 1 : -1));
    let latDeg = parseInt(latStrPos);//int  // * (latNorth ? 1 : -1);
    let latMinAll = (latFloatPos - latDeg) * 60.0; //float
    let latMin = parseInt('' + latMinAll); //int
    //const latSecsAll = + ((latMinAll - latMin) * 60.0).toFixed(6); //float/int, no extra zeroes
    let latSecsAll = + Math.round((latMinAll - latMin) * 60.0); //int
    //handle rounding error in seconds like this: 59.9999 -> 60
    if (latSecsAll == 60) {
        latSecsAll = 0;
        latMin += 1;
        if (latMin == 60) {
            latDeg += 1;
            latMin = 0;
        }
    }

    //longitude
    const lonFloat = vals[1];
    const lonIsEast = lonFloat >= 0.0;
    const lonFloatPos = lonFloat * (lonIsEast ? 1 : -1);
    const lonStrPos = '' + (lonFloat * (lonIsEast ? 1 : -1));
    let lonDeg = parseInt(lonStrPos)//int
    const lonMinAll = (lonFloatPos - lonDeg) * 60.0; //float
    let lonMin = parseInt('' + lonMinAll); //int
    //const lonSecsAll = + ((lonMinAll - lonMin) * 60.0).toFixed(6); //float/int, no extra zeroes
    let lonSecsAll = + Math.round((lonMinAll - lonMin) * 60.0); //int
    //handle rounding error in seconds like this: 59.9999 -> 60
    if (lonSecsAll == 60) {
        lonSecsAll = 0;
        lonMin += 1;
        if (lonMin == 60) {
            lonDeg += 1;
            lonMin = 0;
        }
    }

    return {
        lat: { north: latIsNorth, deg: latDeg, min: latMin, sec: latSecsAll },
        lon: { east: lonIsEast, deg: lonDeg, min: lonMin, sec: lonSecsAll },
    }
}

type Coordinates = {
    lat: { north: boolean, deg: number, min: number, sec: number },
    lon: { east: boolean, deg: number, min: number, sec: number }
};

export type Result = {
    ok: boolean;
    value?: { format: InputFormat, coords: string, },
    error?: ParsingError
}

export type ParsingError = {
    code: ErrorCode, message?: string
};

export enum ErrorCode {
    INVALID_SYNTAX,
    INCORRECT_VALUE
}

export enum InputFormat {
    GOOGLE_MAPS,
    GEOHACK_1,
    GEOHACK_2,
    BOUNDING_BOX
}

const isParsingError = (f: any): f is ParsingError => {
    return (f as ParsingError).code !== undefined
}

function to2digits(num: number) {
    //this only since es2017
    //return String(num).padStart(2, '0')
    return num < 10 ? ('0' + String(num)) : String(num)
}