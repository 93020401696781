import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Identifier extends Item {

    public identifier;
    public attrs;

    static getSelector() {
        return 'identifier';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['_']) {
            this.modsElement['_'] = '';
        }
        if (!this.modsElement['$']) {
            this.modsElement['$'] = {
                type: ''
            };
        }
        if (!this.modsElement['$']['type']) {
            this.modsElement['$']['type'] = '';
        }
        this.attrs = this.modsElement['$'];
        this.identifier = this.modsElement;
    }


    public toDC() {
        let id = '';
        if (this.attrs['type']) {
            id = this.attrs['type'] + ':';
        }
        if (this.identifier['_']) {
            id += this.identifier['_'];
        }
        if (id) {
            return Utils.dcEl('identifier', id);
        } else {
            return '';
        }
    }

}
