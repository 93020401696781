import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-json',
  templateUrl: './editor-json.component.html',
  styleUrls: ['./editor-json.component.css']
})
export class EditorJsonComponent implements OnInit {
  @Input() document: Document;

  constructor() { }

  ngOnInit() {
  }

}
