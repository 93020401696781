import { RelatedItem } from './relateditem.model';
import { Physical } from './physical.model';
import { Identifier } from './identifier.model';
import { Note } from './note.model.';
import { Role } from './role.model';
import { Publisher } from './publisher.model';
import { Language } from './language.model';
import { Keyword } from './keyword.model';
import { Title } from './title.model';
import { Item } from 'app/model/item.model';
import Utils from 'app/utils';
import { Author } from 'app/model/author.model';
import { PLocation } from 'app/model/location.model';
import { Part } from './part.model';

export class Field {

    private selector;
    public root;
    public items: Item[];

    constructor(mods, selector) {
        this.selector = selector;
        if (mods[selector] === undefined) {
            mods[selector] = [];
        }
        this.root = mods[selector];
        this.items = [];
        for (const el of this.root) {
            if (el) {
                this.items.push(this.newInstance(el));
            }
        }
        if (this.items.length < 1) {
            this.add();
        }
    }

    private newInstance(el) {
        switch (this.selector) {
            case Author.getSelector():
                return new Author(el);
            case Title.getSelector():
                return new Title(el);
            case RelatedItem.getSelector():
                return new RelatedItem(el);
            case Keyword.getSelector():
                return new Keyword(el);
            case Language.getSelector():
                return new Language(el);
            case Publisher.getSelector():
                return new Publisher(el);
            case Role.getSelector():
                return new Role(el);
            case Note.getSelector():
                return new Note(el);
            case PLocation.getSelector():
                return new PLocation(el);
            case Identifier.getSelector():
                return new Identifier(el);
            case Physical.getSelector():
                return new Physical(el);
            case Part.getSelector():
                return new Part(el);
        }
    }

    public remove(index) {
        if (index >= 0 && index < this.items.length) {
            this.items.splice(index, 1);
            this.root.splice(index, 1);
        }
    }

    public removeAll() {
        while (this.items.length > 0) {
            this.remove(0);
        }
    }

    public removeObject(obj) {
        const index = this.items.indexOf(obj);
        if (index > -1) {
           this.items.splice(index, 1);
           this.root.splice(index, 1);
        }
    }

    public add(): Item {
        const item: Item = this.newInstance({});
        this.items.push(item);
        this.root.push(item.getEl());
        return item;
    }

    public moveDown(index: number) {
        Utils.moveDown(this.root, index);
        Utils.moveDown(this.items, index);
    }

      public moveUp(index: number) {
        Utils.moveUp(this.root, index);
        Utils.moveUp(this.items, index);
      }

    public toDC() {
        let dc = '';
        for (const item of this.items) {
            dc += item.toDC();
        }
        return dc;
    }

    public update() {
        for (const item of this.items) {
            item.update();
        }
    }

    public firstToDC() {
        let dc = '';
        if (this.items.length > 0) {
            dc = this.items[0].toDC();
        }
        return dc;
    }

    public count(): number {
        return this.items.length;
    }
}
