import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  templateUrl: './renumber-issues-dialog.component.html',
  styleUrls: ['./renumber-issues-dialog.component.scss']
})
export class RenumberIssuesDialogComponent implements OnInit {

  number: number;
  model: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private dialogRef: MatDialogRef<RenumberIssuesDialogComponent>) {
  }

  ngOnInit() {
    this.number = this.data.number || 1;
    this.model = this.data.model;
  }

  onApprove() {
    this.dialogRef.close({
      number: this.number
    });    
  }

}
