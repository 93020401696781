import { Translator } from 'angular-translator';
import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-category',
  templateUrl: './editor-category.component.html',
  styleUrls: ['./editor-category.component.css']
})
export class EditorCategoryComponent implements OnInit {
  @Input() document: Document;

  categories: any[] = [];

  categories_codes: string[] = [
    'photo', 'veduta', 'painting', 'poster', 'postcard',
    'newspaper', 'yearbook', 'magazine', 'addressbook', 'calendar'
  ];

  constructor(public translator: Translator) {
    this.translateCategories();
    translator.languageChanged.subscribe(() => this.translateCategories());
  }

  translateCategories() {
    this.translator.waitForTranslation().then(() => {
      this.categories = [];
      for (const code of this.categories_codes) {
        this.categories.push({code: code, name: this.translator.instant('category.' + code)});
      }
      this.categories.sort((a: any, b: any): number => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });
  }

  ngOnInit() {
  }

}
