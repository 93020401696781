import { Page } from 'app/model/page.model';
import { Volume } from './volume.model';
import { Item } from 'app/model/item.model';
import { Physical } from './physical.model';
import { Identifier } from './identifier.model';
import { Note } from './note.model.';
import Utils from 'app/utils';
import { Publisher } from './publisher.model';
import { Language } from './language.model';
import { Keyword } from './keyword.model';
import { Title } from './title.model';
import { Author } from './author.model';
import { PLocation } from './location.model';
import { Field } from './field.model';
import { parseString, processors, Builder } from 'xml2js';
import { RelatedItem } from './relateditem.model';
import { Connector } from '../utils/connector.model';
import { Part } from './part.model';
import { PeriodicalItem } from './periodicalitem.model';
declare var $: any;


export class Document {

    public readonly uuid: string;
    public readonly originalDc: string;
    public readonly originalMods: string;
    private children: any[];

    public pages: Page[];
    public volumes: PeriodicalItem[];
    public issues: PeriodicalItem[];

    public originalTitle = '';
    private originalPolicy;


    public mods;
    public dc;

    public draftForModel;

    public model;
    public policy;

    public titles: Field;
    public authors: Field;
    public keywords: Field;
    public languages: Field;
    public publishers: Field;
    public notes: Field;
    public locations: Field;
    public identifiers: Field;
    public physicals: Field;
    public relatedItems: Field;
    public parts: Field;

    public volume: Item;

    public categories;

    public collections: string[];
    public originalCollections: string[];

    public originalModsString;
    public originalDcString;

    public enhanced = false;
    public pageOrderChanged = false;
    public volumesRenumbered = false;
    public issuesRenumbered = false;

    private removedPages: string[] = [];

    pidPath: string;

    constructor(uuid: string, mods: string, dc: string, children: any[], collections: string[], pidPath: string) {
        this.uuid = uuid;
        this.originalDc = dc.trim();
        this.originalMods = mods.trim();
        this.children = children;
        this.categories = [];
        this.collections = collections;
        this.pidPath = pidPath; 
        if (!this.collections) {
          this.collections = [];
        }
        this.originalCollections = Object.assign([], this.collections);
        this.parseDc(dc);
        this.parseMods(mods);
    }

    public getCollectionsToAdd(): string[] {
      const collections = [];
      for (const col of this.collections) {
        if (this.originalCollections.indexOf(col) < 0) {
          collections.push(col);
        }
      }
      return collections;
    }

    public getCollectionsToRemove(): string[] {
      const collections = [];
      for (const col of this.originalCollections) {
        if (this.collections.indexOf(col) < 0) {
          collections.push(col);
        }
      }
      return collections;
    }

    public canAddPages(): boolean {
      return this.isPageParent();
    }

    public isUnpublishable(): boolean {
      return this.model === 'monograph' ||
             this.model === 'graphic' ||
             this.model === 'manuscript' ||
             this.model === 'archive' ||
             this.model === 'map';
    }

    public isTopLevel(): boolean {
      return this.model === 'periodical' ||
             this.model === 'monograph' ||
             this.model === 'draft' ||
             this.model === 'graphic' ||
             this.model === 'map' ||
             this.model === 'manuscript' ||
             this.model === 'archive' ||
             this.model === 'sheetmusic';
    }

    public isPageParent(): boolean {
      return this.model === 'periodicalitem' ||
             this.model === 'monograph' ||
             (this.model === 'draft' && this.draftForModel !== 'periodical') ||
             this.model === 'graphic' ||
             this.model === 'map' ||
             this.model === 'manuscript' ||
             this.model === 'archive' ||
             this.model === 'sheetmusic';
    }


    // public removePage(page: Page) {
    //   if (!this.isPageParent()) {
    //     return;
    //   }
    //   const index = page.index - 1;
    //   this.removedPages.push(this.pages[index].pid);
    //   this.pages.splice(index, 1);
    //   for (let i = index; i < this.pages.length; i++) {
    //     this.pages[i].index = i + 1;
    //   }
    // }

    public removePage(page: Page, record: boolean = true) {
      if (!this.isPageParent()) {
        return;
      }
      if (record) {
        this.removedPages.push(page.pid);
      }
      this.pages.splice(this.pages.indexOf(page), 1);
    }

    public removeAllPages() {
      if (!this.isPageParent()) {
        return;
      }
      for (const page of this.pages) {
        this.removedPages.push(page.pid);
      }
      this.pages = [];
    }

    public recalculatePages(page: Page) {
      if (!this.isPageParent()) {
        return;
      }
      const number = parseInt(page.name, 10);
      if (number > 0) {
        let firstIndex = 0;
        let firstNumber = 1;
        const pageIndex = this.pages.indexOf(page) + 1;
        if (number < pageIndex) {
          firstIndex = pageIndex - number;
          firstNumber = 1;
        }
        if (number > pageIndex) {
          firstIndex = 0;
          firstNumber = number - pageIndex + 1;
        }
        let num = 0;
        if (firstIndex > 0) {
          for (let i = 0; i < firstIndex; i++) {
            this.pages[i].name = '';
          }
        }
        for (let i = firstIndex; i < this.pages.length; i++) {
          this.pages[i].name = (firstNumber + num) + '';
          num ++;
        }
      }
    }

    public isPeriodical(): boolean {
      return this.model === 'periodical' || this.draftForModel === 'periodical';
    }

    public isVolume(): boolean {
      return this.model === 'periodicalvolume';
    }

    public isIssue(): boolean {
      return this.model === 'periodicalitem';
    }

    public isVolumeOrIssue(): boolean {
      return this.isVolume() || this.isIssue();
    }

    private parseMods(mods: string) {
        //tohle nefunguje, pokud je MODS na jednom radku, pak se koncova zavorka chyti dal na nejvetsim substringu
        //https://www.reddit.com/r/regex/comments/6c8655/why_matches_the_longest_occurrence_while_matches/
        //const xml = mods.replace(/xmlns.*="(.*?)"/g, ''); 
        const xml = mods.replace(/xmlns="(.*?)"/g, '')  //default namespace definition
                        .replace(/xmlns:.+="(.*?)"/g, ''); //namespace prefix definition
        const data = {tagNameProcessors: [processors.stripPrefix], explicitCharkey: true};
        const ctx = this;
        parseString(xml, data, function (err, result) {
            // TODO: Handle parsing error
            ctx.processMods(result);
        });
    }

    private parseDc(dc: string) {
        const data = {tagNameProcessors: [processors.stripPrefix], explicitCharkey: true};
        const ctx = this;
        parseString(dc, data, function (err, result) {
            // TODO: Handle parsing error
            ctx.processDc(result);
        });
    }

    private processDc(dc) {
        const root = dc['dc'];
        if (!root) {
            return;
        }
        if (root['rights']) {
          this.originalPolicy = root['rights'][0]['_'];
          if (root['rights'][0]['_'] === 'policy:private') {
            this.policy = 'policy:private'
          } else {
            this.policy = 'policy:public';
          }
        } else {
            this.policy = 'policy:public';
        }
        if (root['title']) {
          this.originalTitle = root['title'][0]['_'];
        }

        if (root['type']) {
          for (const t of root['type']) {
            const type = t['_'];
            if (type.startsWith('model:')) {
              this.model = type.substring(6);
            } else if (type.startsWith('category:')) {
              this.addCategory(type.substring(9));
            } else if (type.startsWith('draft:')) {
              this.draftForModel = type.substring(6);
            }
          }
        }
        if (this.categories.length === 0) {
          this.addCategory();
        }
        this.processChildren();
    }

    private processChildren() {
      this.pages = [];
      this.volumes = [];
      this.issues = [];
      // let pageIndex = 0;
      for (const item of this.children) {
        if (item['model'] === 'page') {
          let name = '';
          let type = '';
          // pageIndex += 1;
          const details = item['details'];
          if (details) {
            name = details['pagenumber'];
            type = details['type'];
          }
          this.pages.push(new Page(item['pid'], name, type));
        } else if (item['model'] === 'periodicalvolume') {
          this.volumes.push(new PeriodicalItem(item));
        } else if (item['model'] === 'periodicalitem') {
          this.issues.push(new PeriodicalItem(item));
        }
      }
      this.sortPeriodicalItems(this.volumes);
      this.sortPeriodicalItems(this.issues);
    }

    sortPeriodicalItems(items: PeriodicalItem[]) {
      items.sort((a: PeriodicalItem, b: PeriodicalItem) => {
        if (a.sortIndex == b.sortIndex) {
          return a.sortIndex2 - b.sortIndex2;
        }
        return a.sortIndex - b.sortIndex;
      });
    }



    public anythingToSave(): boolean {
      const collToAdd = this.getCollectionsToAdd();
      const collToRemove = this.getCollectionsToRemove();
      const collectionChanged = collToAdd.length > 0 || collToRemove.length > 0;
      const modsChanged = this.modsChanged();
      const dcChanged = this.dcChanged();
      const policyChanged = this.policyChanged();
      const documentChanged = modsChanged || dcChanged || this.enhanced;
      const pageOrderChanged = this.pageOrderChanged;
      const removedPages = this.getRemovedPages();
      const pages = this.getchangedPages();
      const pagesChanged = pages.length > 0 || removedPages.length > 0
      return pageOrderChanged || documentChanged || collectionChanged ||  pagesChanged || policyChanged || this.volumesRenumbered || this.issuesRenumbered;
    }



    public reorderPages(from: number, to: number) {
      const page = this.pages[from];
      this.pages.splice(from, 1);
      this.pages.splice(to, 0, page);
      this.pageOrderChanged = true;
      // let index = 0;
      // for (const p of this.pages) {
      //   index += 1;
      //   p.index = index;
      // }
    }

    public renumberVolumes(from: PeriodicalItem, number: number) {
      let n = number;
      let i = 0;
      this.volumesRenumbered = true;
      for (const item of this.volumes) {
        if (i > 0) {
          item.subtitle = "" + (n + i);
          i += 1;
        } else {
          if (item == from) {
            item.subtitle = "" + n;
            i = 1
          }
        }
      }
    }

    public renumberIssues(from: PeriodicalItem, number: number) {
      let n = number;
      let i = 0;
      this.issuesRenumbered = true;
      for (const item of this.issues) {
        if (i > 0) {
          item.subtitle = "" + (n + i);
          i += 1;
        } else {
          if (item == from) {
            item.subtitle = "" + n;
            i = 1
          }
        }
      }
    }

    private initElement(root, selector) {
      if (root[selector] === undefined) {
        root[selector] = [];
      }
      return root[selector];
    }

    private processMods(mods) {
      this.mods = mods;
      const modsCollection = mods['modsCollection'];
      modsCollection['$'] = {
        'xmlns': 'http://www.loc.gov/mods/v3'
      };
      if (!modsCollection['mods'][0]) {
        modsCollection['mods'][0] = {};
      }
      const root = modsCollection['mods'][0];


      const addPrefilledPublisher = this.model === 'draft' && root[Publisher.getSelector()] === undefined && Connector.INSTANCE === 'difmoe';
      const publishers = new Field(root, Publisher.getSelector());
      publishers.update();
      this.locations = new Field(root, PLocation.getSelector());
      let haslocationUrl = false;
      for (const location of root['location']) {
        if (location['url'] && location['url'][0] && location['url'][0]['_'] === Connector.getPersistentLink(this.uuid)) {
          haslocationUrl = true;
          break;
        }
      }
      if (!haslocationUrl && Connector.ADD_PERSISTENT_LINK && this.isTopLevel()) {
        root['location'].push({
          'url': [
            {'_': Connector.getPersistentLink(this.uuid) }
          ]
        })
        this.enhanced = true;
      }



      this.titles = new Field(root, Title.getSelector());
      this.relatedItems = new Field(root, RelatedItem.getSelector());
      this.authors = new Field(root, Author.getSelector());
      this.publishers = new Field(root, Publisher.getSelector());

      if (addPrefilledPublisher) {
        this.publishers.add();
        const publisher = <Publisher> this.publishers.items[this.publishers.count() - 1];
        publisher.publisher['_'] = 'Digitales Forum Mittel- und Osteuropa';
        publisher.dateIssued['_'] = '' + new Date().getFullYear();
        publisher.place['_'] = 'München';
        publisher.attrs['eventType'] = 'digitization';
        this.enhanced = true;
      }
      // TODO: Odstranit !!!
      // for (const p of this.publishers.items) {
      //   const publisher = <Publisher> p;
      //   if (publisher.publisher && publisher.publisher['_'].trim() === 'Digitales Forum Mittel- und Osteuropa') {
      //     publisher.dateIssued['_'] = '2018';
      //     this.enhanced = true;
      //   }
      // }

      this.languages = new Field(root, Language.getSelector());
      this.keywords = new Field(root, Keyword.getSelector());
      this.notes = new Field(root, Note.getSelector());
      this.locations = new Field(root, PLocation.getSelector());
      this.identifiers = new Field(root, Identifier.getSelector());
      this.physicals = new Field(root, Physical.getSelector());
      if (this.isVolumeOrIssue()) {
        this.volume = new Volume(root);
      }
      if (this.isTopLevel()) {
        this.parts = new Field(root, Part.getSelector());
      }
      this.originalDcString = this.toDc();
      this.originalModsString = this.toJson();
    }

    addCategory(category = '') {
      this.categories.push({ 'code': category });
    }

    moveDown(array, index: number) {
      if (index < array.length - 1) {
        const x = array[index];
        array[index] = array[index + 1];
        array[index + 1] = x;
      }
    }

    moveUp(array, index: number) {
      if (index > 0) {
        const x = array[index];
        array[index] = array[index - 1];
        array[index - 1] = x;
      }
    }

    removeFromCollection(array: any[], index: number) {
      array.splice(index, 1);
    }


    toMods(save: boolean = false) {
      const builder = new Builder({ 'headless': true });
      const xml = builder.buildObject(this.normalizedCopy(save));
      return xml;
    }


    dcChanged(): boolean {
      return this.originalDcString !== this.toDc();
    }

    modsChanged(): boolean {
      return this.originalModsString !== this.toJson();
      // return true;
    }

    getchangedPages(): Page[] {
      const pages: Page[] = [];
      if (this.pages) {
        for (const page of this.pages) {
          if (page.changed()) {
            pages.push(page);
          }
        }
      }
      return pages;
    }


    getRemovedPages(): string[] {
      return this.removedPages;
    }

    // pageOrderChanged(): boolean {
    //   if (!this.pages) {
    //     return false;
    //   }
    //   for (const page of this.pages) {
    //     if (page.index !== page.originalIndex) {
    //       return true;
    //     }
    //   }
    //   return false;
    // }

    policyChanged(): boolean {
      return this.policy !== this.originalPolicy;
    }


    shouldReindex(): boolean {
      return this.isTopLevel() && this.titleChanged();
    }

    private titleChanged(): boolean {
      let currentTitle = '';
      if (this.titles.items.length > 0) {
        currentTitle = (<Title>(this.titles.items[0])).getFullTitle();
      }
      return currentTitle !== this.originalTitle;
    }

    toDc(): string {
        let dc = '<oai_dc:dc xmlns:oai_dc="http://www.openarchives.org/OAI/2.0/oai_dc/" '
               + 'xmlns:dc="http://purl.org/dc/elements/1.1/" '
               + 'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" '
               + 'xsi:schemaLocation="http://www.openarchives.org/OAI/2.0/oai_dc/ '
               + 'http://www.openarchives.org/OAI/2.0/oai_dc.xsd">\n';
        dc += this.titles.toDC();
        dc += this.authors.toDC();
        dc += this.publishers.toDC();
        dc += this.languages.toDC();
        dc += this.keywords.toDC();
        dc += this.identifiers.toDC();
        dc += this.physicals.toDC();
        dc += this.notes.toDC();
        // if (this.isVolumeOrIssue()) {
        //   dc += this.volume.toDC();
        // }

        if (this.model) {
          dc += Utils.dcEl('type', `model:${this.model}`);
        }
        if (this.draftForModel) {
          dc += Utils.dcEl('type', 'draft:' + this.draftForModel);
        }
        for (const category of this.categories) {
          if (category.code) {
            dc += Utils.dcEl('type', `category:${category.code}`);
          }
        }
        if (this.policy) {
          dc += Utils.dcEl('rights', this.policy);
        }
        dc += '</oai_dc:dc>';
        return dc;
    }

    toJson() {
        return JSON.stringify(this.mods, null, 2);
    }

    private normalize(el) {
      if (el.hasOwnProperty('$')) {
        const attrs = el['$'];
        Object.keys(attrs).forEach(function(key) {
          if (attrs[key] === '') {
            delete attrs[key];
          }
        });
      }
      if (el.hasOwnProperty('_')) {
        if (el['_'] === '') {
          return true;
        } else {
          return false;
        }
      }
      if (el instanceof String) {
        return false;
      }
      if (el instanceof Array) {
        for (let index = el.length - 1; index >= 0; index--) {
          const item = el[index];
          if (this.normalize(item)) {
            el.splice(index, 1);
          }
        }
        if (el.length === 0) {
          return true;
        } else {
          return false;
        }
      }
      if (typeof el === 'object') {
        const ctx = this;
        // if (Object.keys(el).length === 1 && el['$']) {
        //   return true;
        // }
        Object.keys(el).forEach(function(key) {
          if (ctx.normalize(el[key])) {
            delete el[key];
          }
        });
        if (Object.keys(el).length < 1) {
          return true;
        } else {
          return false;
        }
      }
      return false;
    }

    private normalizeField(root, name) {
      if (this.normalize(root[name])) {
        delete root[name];
      }
    }

    private normalizedCopy(final: boolean = false) {

      const mods = $.extend(true, {}, this.mods);
      const root = mods['modsCollection']['mods'][0];

      const publishers = new Field(root, Publisher.getSelector());
      publishers.update();


      if (final) {
        let haslocationUrl = false;
        for (const location of root['location']) {
          if (location['url'] && location['url'][0] && location['url'][0]['_'] === Connector.getPersistentLink(this.uuid)) {
            haslocationUrl = true;
            break;
          }
        }
        if (!haslocationUrl && Connector.ADD_PERSISTENT_LINK) {
          root['location'].push({
            'url': [
              {'_': Connector.getPersistentLink(this.uuid) }
            ]
          })
        }
      }



      this.normalizeField(root, Title.getSelector());
      this.normalizeField(root, RelatedItem.getSelector());
      this.normalizeField(root, Author.getSelector());
      this.normalizeField(root, Publisher.getSelector());
      this.normalizeField(root, Language.getSelector());
      this.normalizeField(root, Keyword.getSelector());
      this.normalizeField(root, Note.getSelector());
      this.normalizeField(root, PLocation.getSelector());
      this.normalizeField(root, Identifier.getSelector());
      this.normalizeField(root, Physical.getSelector());
      if (this.isVolumeOrIssue()) {
        this.normalizeField(root, 'part');
      }
      if (this.isTopLevel()) {
        this.normalizeField(root, Part.getSelector());
      }
      return mods;
    }









}
