import { Role } from './role.model';
import { Field } from './field.model';
import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Author extends Item {

    public name;
    public given;
    public family;
    public date;
    public roles: Field;

    static getSelector() {
        return 'name';
    }

    constructor(modsElement) {
        super(modsElement, ['type']);
        this.init();
    }


    private init() {
        if (!this.modsElement['namePart']) {
            this.modsElement['namePart'] = [];
        }
        const nameParts = this.modsElement['namePart'];
        for (const namePart of nameParts) {
            if (!Utils.hasAnyAttribute(namePart)) {
                this.name = namePart;
            }  else if (Utils.hasAttributeWithValue(namePart, 'type', 'date')) {
                this.date = namePart;
            }  else if (Utils.hasAttributeWithValue(namePart, 'type', 'family')) {
                this.family = namePart;
            }  else if (Utils.hasAttributeWithValue(namePart, 'type', 'given')) {
                this.given = namePart;
            }
        }
        if (this.name == null) {
            this.name = Utils.createTextElement('', null);
            nameParts.push(this.name);
        }
        if (this.date == null) {
            this.date = Utils.createTextElement('', {'type': 'date'});
            nameParts.push(this.date);
        }
        if (this.family == null) {
            this.family = Utils.createTextElement('', {'type': 'family'});
            nameParts.push(this.family);
        }
        if (this.given == null) {
            this.given = Utils.createTextElement('', {'type': 'given'});
            nameParts.push(this.given);
        }
        this.roles = new Field(this.modsElement, Role.getSelector());
        // this.splitName();
    }


    public splitName() {
          const nameParts = this.name['_'].split(',');
          if (nameParts.length === 2) {
            this.family['_'] = nameParts[0].trim();
            this.given['_'] = nameParts[1].trim();
            this.name['_'] = '';
          }
      }

    public toDC() {
        if ((this.family && this.family['_']) && (this.given && this.given['_'])) {
            return Utils.dcEl('creator', this.family['_'] + ', ' + this.given['_']);
        } else if (this.family && this.family['_']) {
            return Utils.dcEl('creator', this.family['_']);
        } else if (this.given && this.given['_']) {
            return Utils.dcEl('creator', this.given['_']);
        } else if (this.name && this.name['_']) {
            return Utils.dcEl('creator', this.name['_']);
        } else {
            return '';
        }
    }

}
