import { Connector } from './../utils/connector.model';
import { Page } from 'app/model/page.model';
import { UnauthorizedError } from './../common/errors/unauthorized-error';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Document } from './../model/document.model';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';


import { AppError } from '../common/errors/app-error';
import { NotFoundError } from '../common/errors/not-found-error';
import { PeriodicalItem } from 'app/model/periodicalitem.model';

@Injectable()
export class BackendService {


  private loggedIn = false;

  private EDITOR_BACKEND_URL: string;
  private IMAGE_UPLOAD_BACKEND_URL: string;
  private JAVA_BACKEND_URL: string;


  constructor(private http: Http) {
    this.IMAGE_UPLOAD_BACKEND_URL = Connector.IMAGE_UPLOAD_BACKEND_URL;
    this.EDITOR_BACKEND_URL = Connector.EDITOR_BACKEND_URL;
    this.JAVA_BACKEND_URL = Connector.JAVA_BACKEND_URL;
  }

  testBackend() {

    console.log("token: " + this.getToken());
    const formData: any = new FormData();
    formData.append('token', this.getToken());

    //funguje
    //const url = this.buildNodeBackendUrl('auth-test');

    const url = this.buildJavaBackendUrl('auth-test');


    const params = {
      'data': 'bla',
      'token': this.getToken()
    };
    this.http.post(url, params)
      //this.http.post(url, formData)
      //     .map(response => response.json())
      .catch(this.handleError)
      .subscribe(response => {
        console.log(response);
      });
  }

  // Documents


  updateDocument(document: Document) {
    const url = this.buildEditorBackendUrl('update_document');
    const params = {
      'uuid': document.uuid,
      'mods': document.toMods(true),
      'dc': document.toDc(),
      'reindex': document.shouldReindex(),
      'token': this.getToken()
    };
    return this.http.post(url, params)
      //     .map(response => response.json())
      .catch(this.handleError);
  }

  createDocument(uuid: string, policy: string, title: string, model: string) {
    const url = this.buildEditorBackendUrl('create_document');
    const params = {
      'uuid': uuid,
      'policy': policy,
      'title': title,
      'model': model,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }

  deleteDocument(uuid: string) {
    const url = this.buildEditorBackendUrl('delete_document');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }



  // Volumes
  createVolume(uuid: string, policy: string, number: string, year: string) {
    const url = this.buildEditorBackendUrl('create_volume');
    const params = {
      'uuid': uuid,
      'policy': policy,
      'number': number,
      'year': year,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  updateVolume(uuid: string, policy: string, number: string, year: string) {
    const url = this.buildEditorBackendUrl('update_volume');
    const params = {
      'uuid': uuid,
      'policy': policy,
      'number': number,
      'year': year,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  updateVolumes(document_uuid: string, volumes: PeriodicalItem[]) {
    const url = this.buildEditorBackendUrl('update_volumes');
    const params = {
      'volumes': PeriodicalItem.toVolumeJsonArray(volumes),
      'document': document_uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }

  removeVolume(uuid: string) {
    const url = this.buildEditorBackendUrl('remove_volume');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  // Issues
  createIssue(uuid: string, policy: string, number: string, date: string) {
    const url = this.buildEditorBackendUrl('create_issue');
    const params = {
      'uuid': uuid,
      'policy': policy,
      'number': number,
      'date': date,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }
  
  updateIssue(uuid: string, policy: string, number: string, date: string) {
    const url = this.buildEditorBackendUrl('update_issue');
    const params = {
      'uuid': uuid,
      'policy': policy,
      'number': number,
      'date': date,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  updateIssues(document_uuid: string, issues: PeriodicalItem[]) {
    const url = this.buildEditorBackendUrl('update_issues');
    const params = {
      'issues': PeriodicalItem.toIssueJsonArray(issues),
      'document': document_uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }

  removeIssue(uuid: string) {
    const url = this.buildEditorBackendUrl('remove_issue');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  moveIssues(uuids: [string], from: string, to: string) {
    const url = this.buildEditorBackendUrl('move_issues');
    const params = {
      'uuids': uuids,
      'from': from,
      'to': to,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }


  movePages(uuids: [string], from: string, to: string) {
    const url = this.buildEditorBackendUrl('move_pages');
    const params = {
      'uuids': uuids,
      'from': from,
      'to': to,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }


  getImagePath(uuid: string) {
    const url = this.buildEditorBackendUrl('image_path');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  // Collections


  newCollection(nameEn: string, nameCs: string) {
    const url = this.buildEditorBackendUrl('create_collection');
    const params = {
      'name_en': nameEn,
      'name_cs': nameCs,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  updateCollection(pid: string, nameEn: string, nameCs: string) {
    const url = this.buildEditorBackendUrl('update_collection');
    const params = {
      'pid': pid,
      'name_en': nameEn,
      'name_cs': nameCs,
      'token': this.getToken()
    };
    return this.http.patch(url, params)
      .map(response => response.json())
      .catch(this.handleError);
  }

  deleteCollection(pid: string) {
    const url = this.buildEditorBackendUrl('delete_collection');
    const params = {
      'pid': pid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }

  collectionMembership(document: string, addCollection: string[], removeCollection: string[]) {
    const url = this.buildEditorBackendUrl('collection_membership');
    const params = {
      'document': document,
      'token': this.getToken()
    };
    if (addCollection.length > 0) {
      params['add'] = addCollection.join(',');
    }
    if (removeCollection.length > 0) {
      params['remove'] = removeCollection.join(',');
    }
    return this.http.post(url, params)
      .catch(this.handleError);
  }


  // Pages

  updatePages(document_uuid: string, pages: Page[], policy: string) {
    const url = this.buildEditorBackendUrl('update_pages');
    const params = {
      'pages': Page.toJsonArray(pages),
      'policy': policy,
      'document': document_uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }

  createPages(document_uuid: string, policy: string, pages: Page[], with_ocr: boolean) {
    const url = this.buildEditorBackendUrl('create_pages');
    const params = {
      'pages': Page.toJsonArray(pages),
      'policy': policy,
      'document': document_uuid,
      'token': this.getToken(),
      'with_ocr': with_ocr
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }


  deletePages(document_uuid: string, uuids: string[]) {
    const url = this.buildEditorBackendUrl('delete_pages');
    const params = {
      'uuids': uuids,
      'document': document_uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      .catch(this.handleError);
  }

  // rotatePage(uuid: string, angle: number) {
  //   const url = this.buildEditorBackendUrl('rotate_page');
  //   const params = {
  //     'uuid': uuid,
  //     'angle': angle,
  //     'token': this.getToken()
  //   };
  //   return this.http.post(url, params)
  //     // .map(response => response.json())
  //     .catch(this.handleError);
  // }

  // Policy
  makePublic(uuid: string) {
    const url = this.buildEditorBackendUrl('make_public');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }


  makePrivate(uuid: string) {
    const url = this.buildEditorBackendUrl('make_private');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }

  publish(uuid: string, model: string) {
    const url = this.buildEditorBackendUrl('publish');
    const params = {
      'uuid': uuid,
      'model': model,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }

  unpublish(uuid: string) {
    const url = this.buildEditorBackendUrl('unpublish');
    const params = {
      'uuid': uuid,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }


  reorderPages(uuid: string, pages: string[]) {
    console.log('pages uuid', pages);
    const url = this.buildEditorBackendUrl('reorder_pages');
    const params = {
      'uuid': uuid,
      'pages': pages,
      'token': this.getToken()
    };
    return this.http.post(url, params)
      // .map(response => response.json())
      .catch(this.handleError);
  }


  // Rels-ext
  // updateRelsExt(uuid: string, policy: string, model: string, pages: Page[], collections: string[]) {
  //   const url = this.buildEditorBackendUrl('update_rels_ext');
  //   const params = {
  //     'uuid': uuid,
  //     'policy': policy,
  //     'model': model,
  //     'pages': Page.toJsonArray(pages),
  //     'collections': collections,
  //     'token': this.getToken()
  //   };
  //   return this.http.post(url, params)
  //     // .map(response => response.json())
  //     .catch(this.handleError);
  // }



  changePageImage(document_uuid: string, uuid: string, files: Array<File>) {
    // const formData: any = new FormData();
    // for (let i = 0; i < files.length; i++){
    //     formData.append('uploads[]', files[i], files[i]['name']);
    // }
    // formData.append('document_uuid', document_uuid);
    // formData.append('page_uuid', uuid);
    // formData.append('token', this.getToken());
    // return this.http.post('http://www.difmoe.eu:3003/upload', formData)
    //     .map(result => result.json())
    return this.uploadNewImages(document_uuid, [uuid], files);
  }


  rotatePage(file: string, angle: number) {
    const formData: any = new FormData();
    formData.append('file', file);
    formData.append('angle', angle);
    formData.append('token', this.getToken());
    return this.http.post(this.buildImageUploadBackendUrl("rotate"), formData)
      .map(result => result.json());
  }

  uploadNewImages(document_uuid: string, uuids: string[], files: Array<File>) {
    const formData: any = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append('uploads[]', files[i], files[i]['name']);
    }
    formData.append('document_uuid', document_uuid);
    formData.append('uuids', uuids);
    formData.append('token', this.getToken());
    return this.http.post(this.buildImageUploadBackendUrl("upload"), formData)
      .map(result => result.json());
  }

  uploadNewPdf(file: File) {
    const formData: any = new FormData();
    formData.append('token', this.getToken());
    formData.append('file', file);
    const url = this.buildJavaBackendUrl("pdf-upload");
    return this.http.post(url, formData)
      .map(result => result.json());
  }

  convertUploadedPdf(document_pid: string, page_pids: string[], pdf_filename: string) {
    const data = {
      token: this.getToken(),
      pdfFilename: pdf_filename,
      documentPid: document_pid,
      pagePids: page_pids
    }
    const url = this.buildJavaBackendUrl("pdf-convert");
    return this.http.post(url, data)
      //.map(result => result.json());
  }

  update_token() {
    const url = this.buildEditorBackendUrl('update_token');
    return this.http.post(url, { token: this.getToken() })
      .map(response => {
        console.log('aar',response.status)
        const result = response.json();
        if (result && result.token) {
          localStorage.setItem('token', result.token);
        }
      });
  }

  login(credentials) {
    const url = this.buildEditorBackendUrl('authenticate');
    return this.http.post(url, credentials)
      .map(response => {
        const result = response.json();
        // console.log('result', result);
        if (result && result.token) {
          localStorage.setItem('token', result.token);
          this.isLoggedIn();
          const decoded = new JwtHelperService().decodeToken(result.token);
          return decoded;
        } else {
          return null;
        }
      })
      .catch(this.handleError);
  }


  logout() {
    this.loggedIn = false;
    localStorage.removeItem('token');
  }

  hasRole(role) {
    const token = this.getToken();
    const decoded = new JwtHelperService().decodeToken(token);
    if (!decoded || !decoded['editor_enabled'] || !decoded['roles']) {
      return false;
    }
    return decoded['roles'].indexOf(role) >= 0;

  }

  isLoggedIn() {
    const token = this.getToken();
    const decoded = new JwtHelperService().decodeToken(token);
    // console.log('decoded', decoded);
    if (!decoded || !decoded['editor_enabled']) {
      this.loggedIn = false;
    } else {
      this.loggedIn = true;
    }
    return this.loggedIn;
    // // tokenNotExpired();
    // console.log('isLoggedIn');
    // const token = this.getToken();
    // console.log('isLoggedIn token:', token);
    // if (!token) {
    //   return false;
    // }
    // const jwtHelper = new JwtHelperService();
    // const isExpired = jwtHelper.isTokenExpired(token);

    // const expidationDate = jwtHelper.getTokenExpirationDate(token);
    // console.log('isLoggedIn isExpired:', isExpired);
    // console.log('isLoggedIn expidationDate:', expidationDate);

    // return !isExpired;
  }

  // currentUser() {
  //   const token = this.getToken();
  //   if (!token) {
  //     return null;
  //   }
  //   return new JwtHelperService().decodeToken(token);
  // }


  getAutocompleteUrl(field: string, q: string) {
    return this.buildEditorBackendUrl('autocomplete.json?key=' + field + '&q=' + q);
  }

  private getToken() {
    return localStorage.getItem('token');
  }


  private buildEditorBackendUrl(path: string) {
    return this.EDITOR_BACKEND_URL + '/' + path;
  }

  private buildImageUploadBackendUrl(path: string) {
    return this.IMAGE_UPLOAD_BACKEND_URL + '/' + path;
  }

  private buildJavaBackendUrl(path: string) {
    return this.JAVA_BACKEND_URL + '/' + path;
  }

  private handleError(error: Response) {
    if (error.status === 404) {
      return Observable.throw(new NotFoundError());
    } else if (error.status === 401) {
      return Observable.throw(new UnauthorizedError());
    }
    return Observable.throw(new AppError(error));
  }




}
