import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { BackendService } from 'app/services/backend.service';


@Component({
  selector: 'app-page-rotation-dialog',
  templateUrl: './page-rotation-dialog.component.html',
  styleUrls: ['./page-rotation-dialog.component.scss']
})
export class PageRotationDialogComponent implements OnInit {

  pid;
  angle = null;
  inProgress: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) data: any, 
              private backed: BackendService,
              private dialogRef: MatDialogRef<PageRotationDialogComponent>) {
                dialogRef.disableClose = true;
                this.pid = data['pid'];
  }

  ngOnInit() {
    this.inProgress = false;
  }

  rotate() {
    // console.log('image rotation', 'pid: ' + this.pid + ', angle: ' + this.angle);
    const angle = this.angle;
    this.inProgress = true;
    this.backed.getImagePath(this.pid).subscribe((r) => {
      const imagePath = r['path'];
      this.backed.rotatePage(imagePath, angle).subscribe((response) => {
        console.log('reponse', response);
        this.dialogRef.close("rotation");
      });
    });
  }

}
