import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-unpublish-document-dialog',
  templateUrl: './unpublish-document-dialog.component.html',
  styleUrls: ['./unpublish-document-dialog.component.scss']
})
export class UnpublishDocumentDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
