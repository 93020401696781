import { AuthorAutocompleteService } from './../../services/autocomplete/author-autocomplete.service';
import { Field } from './../../model/field.model';
import { Component, OnInit, Input } from '@angular/core';

import { Translator } from 'angular-translator';
import { Author } from 'app/model/author.model';
import { Role } from 'app/model/role.model';

@Component({
  selector: 'app-editor-author',
  templateUrl: './editor-author.component.html',
  styleUrls: ['./editor-author.component.scss']
})
export class EditorAuthorComponent implements OnInit {
  @Input() field: Field;

  types: string[] = ['', 'personal', 'corporate'];

  roleCodes = ['act', 'adp', 'aft', 'ann', 'ant', 'app', 'aqt', 'arc', 'arr', 'art', 'asg', 'asn', 'att', 'auc', 'aud', 'aui', 'aus',
        'aut', 'bdd', 'bjd', 'bkd', 'bkp', 'bnd', 'bpd', 'bsl', 'ccp', 'chr', 'cli', 'cll', 'clt', 'cmm', 'cmp', 'cmt',
        'cnd', 'cns', 'coe', 'col', 'com', 'cos', 'cot', 'cov', 'cpc', 'cpe', 'cph', 'cpl', 'cpt', 'cre', 'crp', 'crr', 'csl',
        'csp', 'cst', 'ctb', 'cte', 'ctg', 'ctr', 'cts', 'ctt', 'cur', 'cwt', 'dfd', 'dfe', 'dft', 'dgg', 'dis', 'dln', 'dnc',
        'dnr', 'dpc', 'dpt', 'drm', 'drt', 'dsr', 'dst', 'dte', 'dto', 'dub', 'edt', 'egr', 'elt', 'eng', 'etr', 'exp', 'fac',
        'flm', 'fmo', 'fnd', 'frg', 'grt', 'hnr', 'hst', 'ill', 'ilu', 'ins', 'inv', 'itr', 'ive', 'ivr', 'lbt', 'lee', 'lel',
        'len', 'let', 'lie', 'lil', 'lit', 'lsa', 'lse', 'lso', 'ltg', 'lyr', 'mdc', 'mod', 'mon', 'mrk', 'mte', 'mus', 'nrt',
        'opn', 'org', 'orm', 'oth', 'own', 'pat', 'pbd', 'pbl', 'pfr', 'pht', 'plt', 'pop', 'ppm', 'prc', 'prd', 'prf', 'prg',
        'prm', 'pro', 'prt', 'pta', 'pte', 'ptf', 'pth', 'ptt', 'rbr', 'rce', 'rcp', 'red', 'ren', 'res', 'rev', 'rpt', 'rpy',
        'rse', 'rsp', 'rst', 'rth', 'rtm', 'sad', 'sce', 'scr', 'scl', 'sec', 'sgn', 'sng', 'spk', 'spn', 'srv', 'stn', 'stl',
        'str', 'ths', 'trc', 'trl', 'tyd', 'tyg', 'voc', 'wam', 'wdc', 'wde', 'wit']

  roles = [];

  constructor(public translator: Translator, private authorAutocomplete: AuthorAutocompleteService) {
    this.translateRoles();
    translator.languageChanged.subscribe(() => this.translateRoles());
  }


  onAuthorSelected(author: Author, obj) {
    console.log('author', author);
    author.splitName();
    if (obj && obj.data) {
      const data = obj.data;
      author.roles.removeAll();
      if (data['date']) {
        author.date['_'] = data['date'];
      }
      if (data['type']) {
        author.attrs['type'] = data['type'];
      }
      if (data['roles']) {
        for (const role of data['roles']) {
          (<Role> author.roles.add()).role['_'] = role;
        }
      }
    }
  }

  translateRoles() {
    this.translator.waitForTranslation().then(() => {
      this.roles = [];
      for (const code of this.roleCodes) {
        this.roles.push({code: code, name: this.translator.instant('role.' + code)});
      }
      this.roles.sort((a: any, b: any): number => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    });
  }

  ngOnInit() {
  }

}
