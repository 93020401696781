import { Title } from './title.model';
import { Field } from './field.model';
import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class RelatedItem extends Item {

    public titles: Field;

    static getSelector() {
        return 'relatedItem';
    }

    constructor(modsElement) {
        super(modsElement, ['type']);
        this.init();
    }

    private init() {
        this.titles = new Field(this.modsElement, Title.getSelector());
    }
    public toDC() {
        return '';
    }

}
