import { environment } from './../../environments/environment';
import { PublishDocumentDialogComponent } from './../dialogs/publish-document-dialog/publish-document-dialog.component';
import { UnpublishDocumentDialogComponent } from './../dialogs/unpublish-document-dialog/unpublish-document-dialog.component';
import { Translator } from 'angular-translator';
import { DocumentSaveDialogComponent } from './../dialogs/document-save-dialog/document-save-dialog.component';
import { NotLoggedInDialogComponent } from './../dialogs/not-logged-in-dialog/not-logged-in-dialog.component';
import { MatDialog, MatDialogConfig, MatSnackBar, MatDialogRef, MatTabGroup } from '@angular/material';
import { BackendService } from './../services/backend.service';
import { NotFoundError } from './../common/errors/not-found-error';
import { AppError } from './../common/errors/app-error';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Document } from '../model/document.model';
import { KrameriusService } from '../services/kramerius.service';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';
import 'rxjs/add/operator/map';
import { RemoveDocumentDialogComponent } from 'app/dialogs/remove-document-dialog/remove-document-dialog.component';
import { Connector } from '../utils/connector.model';
import { Page } from 'app/model/page.model';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss']
})

export class DocumentComponent implements OnInit {

  document: Document;
  showJsonTab: boolean;
  snackBarText = {
    message: '',
    action: ''
  };

  constructor(private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
    private translator: Translator,
    private krameriusService: KrameriusService,
    private backendService: BackendService) {

    this.showJsonTab = !environment.production;
    this.setTexts();
    translator.languageChanged.subscribe(() => this.setTexts());
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.document = null;
      const uuid = params.get('uuid');
      console.log('reseting left tab index;');
      this.loadDocument(uuid);
    });
  }


 dkLink(): string {
   if (!this.document) {
     return "";
   }
   if (this.document.draftForModel == 'periodical') {
    return Connector.getFrontendLink('periodical', this.document.uuid);
   } else {
      return Connector.getFrontendLink('uuid', this.document.uuid);
   }
  }


  loadDocument(uuid: string) {
    const ctx = this;

    const modsRequest = this.krameriusService.getMods(uuid);
    const dcRequest = this.krameriusService.getDc(uuid);
    const childrenRequest = this.krameriusService.getChildren(uuid);
    const solrRequest = this.krameriusService.getSolrResult('fl=collection,pid_path&q=PID:"' + uuid + '"');

    Observable.forkJoin([modsRequest, dcRequest, childrenRequest, solrRequest]).subscribe(
      results => {
        const mods = results[0];
        const dc = results[1];
        const children = results[2];
        let collections = [];
        let pidPath = "";
        const solr = results[3];
        const doc = solr['response']['docs'][0];
        if (!!doc) {
          collections = doc['collection'] || [];
          pidPath = doc['pid_path'][0];
        }
        this.document = new Document(uuid, mods, dc, children, collections, pidPath);
        if (!this.backendService.isLoggedIn()) {
          this.openNotLoggedInDialog();
        }
      },
      (error: AppError) => {
        if (error instanceof NotFoundError) {
          this.router.navigate(['/search'], {
            queryParams: { 'error': 'not_found' }
          });
        }
      }
    );
  }


  remove() {
    const options = {
      data: { 'pid': this.document.uuid }
    };
    const dialogRef = this.dialog.open(RemoveDocumentDialogComponent, options);
    dialogRef.afterClosed().subscribe(result => {
      if (result && result === 'close-after-success') {
        this.router.navigate(['/drafts']);
      }
    });
  }

  onPublish() {
    const options = {
      data: { 'model': this.document.draftForModel }
    };
    this.dialog.open(PublishDocumentDialogComponent, options).afterClosed()
      .subscribe(result => {
        if (result && result.startsWith('model:')) {
          const model = result.substring(6);
          this.publish(model);
        }
      });
  }

  publish(asModel: string) {
    this.document.model = asModel;
    this.document.draftForModel = null;
    this.save(Save.PUBLISH);
  }

  onUnpublish() {
    this.dialog.open(UnpublishDocumentDialogComponent).afterClosed()
      .subscribe(result => {
        if (result === 'unpublish') {
          this.unpublish();
        }
      });
  }

  unpublish() {
    const model = this.document.model;
    this.document.model = 'draft';
    this.document.draftForModel = model;
    this.save(Save.UNPUBLISH);
  }

  update() {
    this.save(Save.UPDATE);
  }

  onLeftTabChanged(event) {
    console.log('onLeftTabChanged', event);
    if ((this.document.isIssue() || this.document.isVolume()) && event.index == 1) {
      this.goUp();
    }
  }

  goUp() {
    this.krameriusService.getItem(this.document.uuid).subscribe(item => {
      if (item['context'] && item['context'].length > 0 && item['context'][0].length > 1) {
        const pid = item['context'][0][item['context'][0].length - 2]['pid'];
        this.router.navigate(['/documents', pid]);
      }
    });
  }

  private save(type: Save) {
    const collToAdd = this.document.getCollectionsToAdd();
    const collToRemove = this.document.getCollectionsToRemove();
    const collectionChanged = collToAdd.length > 0 || collToRemove.length > 0;
    const modsChanged = this.document.modsChanged();
    const dcChanged = this.document.dcChanged();
    const policyChanged = this.document.policyChanged();
    const documentChanged = modsChanged || (dcChanged && !policyChanged) || this.document.enhanced;
    const pageOrderChanged = this.document.pageOrderChanged;
    const volumesRenumbered = this.document.volumesRenumbered;
    const issuesRenumbered = this.document.issuesRenumbered;
    const removedPages = this.document.getRemovedPages();
    const pages = this.document.getchangedPages();
    const pagesChanged = pages.length > 0 || removedPages.length > 0

    if (!volumesRenumbered && !issuesRenumbered && !pageOrderChanged && !documentChanged && !collectionChanged && !pagesChanged && type === Save.UPDATE && !policyChanged) {
      this.openSnackBar(this.snackBarText.message, this.snackBarText.action);
      return;
    }
    const config = new MatDialogConfig();
    config.disableClose = true;
    const dialogRef: MatDialogRef<DocumentSaveDialogComponent> = this.dialog.open(DocumentSaveDialogComponent, config);
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'dk') {
        window.location.href = this.dkLink();
      } else if (result === 'close') {
        this.router.navigate(['/']);
      }
    });

    const requestArray = [];
    if (policyChanged && this.document.policy === 'policy:private') {
      const makePrivateRequest = this.backendService.makePrivate(this.document.uuid);
      requestArray.push(makePrivateRequest);
    }
    if (documentChanged) {
      const documenRequest = this.backendService.updateDocument(this.document);
      requestArray.push(documenRequest);
    }
    if (collectionChanged) {
      const collectionRequest = this.backendService.collectionMembership(this.document.uuid, collToAdd, collToRemove);
      requestArray.push(collectionRequest);
    }
    if (pagesChanged) {
      const pagesRequest = this.backendService.updatePages(this.document.uuid, pages, this.document.policy);
      requestArray.push(pagesRequest);
    }
    if (policyChanged && this.document.policy === 'policy:public') {
      const makePublicRequest = this.backendService.makePublic(this.document.uuid);
      requestArray.push(makePublicRequest);
    }
    if (pageOrderChanged) {
      const reoredrPagesRequest = this.backendService.reorderPages(this.document.uuid, this.document.pages.map((page: Page) => page.pid));
      requestArray.push(reoredrPagesRequest);
    }
    if (issuesRenumbered) {
      const issuesRenumberedRequest = this.backendService.updateIssues(this.document.uuid, this.document.issues);
      requestArray.push(issuesRenumberedRequest);
    }
    if (volumesRenumbered) {
      const volumesRenumberedRequest = this.backendService.updateVolumes(this.document.uuid, this.document.volumes);
      requestArray.push(volumesRenumberedRequest);
    }
    if (type === Save.PUBLISH) {
      const publishRequest = this.backendService.publish(this.document.uuid, this.document.model);
      requestArray.push(publishRequest);
    } else if (type === Save.UNPUBLISH) {
      const unpublishRequest = this.backendService.unpublish(this.document.uuid);
      requestArray.push(unpublishRequest);
    }
    if (removedPages.length > 0) {
      const removePagesRequest = this.backendService.deletePages(this.document.uuid, removedPages);
      requestArray.push(removePagesRequest);
    }
    Observable.forkJoin(requestArray).subscribe(
      results => {
        dialogRef.componentInstance.changeState(type);
      },
      error => {
        console.log('error', error);
      }
    );
  }

  private setTexts() {
    this.translator.waitForTranslation().then(() => {
      this.snackBarText.message = String(this.translator.instant('common.nothing_to_save'));
      this.snackBarText.action = String(this.translator.instant('common.close'));
    });
  }

  private openNotLoggedInDialog() {
    if (localStorage.getItem('dnsawd') !== 'yes') {
      this.dialog.open(NotLoggedInDialogComponent);
    }
  }


  private openSnackBar(message: string, action: string) {
    const snackBarRef = this.snackBar.open(message, action, {
      duration: 2000,
    });
    snackBarRef.onAction().subscribe(() => {
      snackBarRef.dismiss();
    });
  }

}


export enum Save {
  UPDATE,
  PUBLISH,
  UNPUBLISH
}
