import { Collection } from './../../model/collection.model';
import { UnauthorizedError } from './../../common/errors/unauthorized-error';
import { AppError } from './../../common/errors/app-error';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';


@Component({
  selector: 'app-new-collection-dialog',
  templateUrl: './new-collection-dialog.component.html',
  styleUrls: ['./new-collection-dialog.component.scss']
})
export class NewCollectionDialogComponent implements OnInit {

  loginError = false;
  inProgress = false;
  editMode = false;
  nameEn: string;
  nameCs: string;
  pid: string;
  localePath: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private backendService: BackendService,
              private dialogRef: MatDialogRef<NewCollectionDialogComponent>) {

    if (data && data['nameEn']) {
      this.editMode = true;
      this.pid = data['pid'];
      this.nameEn = data['nameEn'];
      this.nameCs = data['nameCs'];
      this.localePath = 'edit_collection';
    } else {
      this.nameEn = '';
      this.nameCs = '';
      this.localePath = 'new_collection';
    }
  }

  ngOnInit() {
  }


  createCollection() {
    if (!this.nameCs) {
      this.nameCs = this.nameEn;
    }
    this.inProgress = true;
    this.loginError = false;
    if (this.editMode) {
      this.updateCollection();
    } else {
      this.newCollection();
    }
  }


  private newCollection() {
    this.backendService.newCollection(this.nameEn, this.nameCs)
    .subscribe(
      response => {
        this.inProgress = false;
        this.dialogRef.close({
          pid: response['pid'],
          nameEn: this.nameEn,
          nameCs: this.nameCs
        });
      },
      error => {
        this.inProgress = false;
        this.loginError = true;
      }
    );
  }

  private updateCollection() {
    this.backendService.updateCollection(this.pid, this.nameEn, this.nameCs)
    .subscribe(
      response => {
        // console.log('response --- ', response);
        this.inProgress = false;
        this.dialogRef.close({
          pid: this.pid,
          nameEn: this.nameEn,
          nameCs: this.nameCs
        });
      },
      error => {
        this.inProgress = false;
        this.loginError = true;
      }
    );
  }

}
