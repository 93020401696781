import { DomSanitizer } from '@angular/platform-browser';
import { KrameriusService } from './../../../services/kramerius.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Component, OnInit, Inject } from '@angular/core';
import { PageRotationDialogComponent } from '../page-rotation-dialog/page-rotation-dialog.component';
import { BackendService } from 'app/services/backend.service';


@Component({
  selector: 'app-page-image-dialog',
  templateUrl: './page-image-dialog.component.html',
  styleUrls: ['./page-image-dialog.component.scss']
})
export class PageImageDialogComponent implements OnInit {

  pid;
  timestamp;

  constructor(@Inject(MAT_DIALOG_DATA) data: any,
              private krameriusService: KrameriusService,
              private dialog: MatDialog,
              public backendService: BackendService,
              private _sanitizer: DomSanitizer) {
                this.pid = data['pid'];
  }

  ngOnInit() {
    this.updateTimestamp();
  }

  private updateTimestamp() {
    this.timestamp = new Date().getTime();
  }

  getThumb() {
    const url = this.krameriusService.getFullImageUrl(this.pid);
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url}&t=${this.timestamp})`);
  }

  rotate() {
    const options = {
      data: {'pid': this.pid}
    };
    const dialogRef = this.dialog.open(PageRotationDialogComponent, options);
    dialogRef.afterClosed().subscribe((result) => {
      if (result == "rotation") {
        this.updateTimestamp();
      }
    })
  }


}
