import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-identifier',
  templateUrl: './editor-identifier.component.html',
  styleUrls: ['./editor-identifier.component.css']
})
export class EditorIdentifierComponent implements OnInit {
  @Input() field: Field;

  types: string[] = [
    'doi', 'ean', 'isbn', 'iso', 'issn', 'uri', 'url', 'uuid'
  ];

  constructor() {}

  ngOnInit() {
  }


}
