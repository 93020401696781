import { UnauthorizedError } from './../../common/errors/unauthorized-error';
import { AppError } from './../../common/errors/app-error';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';


@Component({
  selector: 'app-login-dialog',
  templateUrl: './login-dialog.component.html',
  styleUrls: ['./login-dialog.component.scss']
})
export class LoginDialogComponent implements OnInit {

  loginError: string = null;
  inProgress = false;

  constructor(private backendService: BackendService,
              private dialogRef: MatDialogRef<LoginDialogComponent>) {
  }

  ngOnInit() {
  }


    signIn(credentials) {
      this.inProgress = true;
      this.backendService.login(credentials)
        .subscribe(
          result => {
            this.inProgress = false;
            console.log('Sign In result', result);
            if (result) {
              if (result.editor_enabled) {
                this.dialogRef.close(true);
              } else {
                this.backendService.logout();
                this.loginError = 'insufficient_permissions';
              }
            } else {
              this.loginError = 'login_failure';
            }
          },
          (error: AppError) => {
            this.inProgress = false;
            if (error instanceof UnauthorizedError) {
              this.loginError = 'invalid_credentials';
            } else {
              this.loginError = 'connection_failure';
            }
          }
        );
    }

}
