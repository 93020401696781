import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-note',
  templateUrl: './editor-note.component.html',
  styleUrls: ['./editor-note.component.css']
})
export class EditorNoteComponent implements OnInit {
  @Input() field: Field;

  types: string[] = [
    'date/sequential designation', 'thesis'
  ];

  constructor() { }

  ngOnInit() {
  }

}
