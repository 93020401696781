import { GeonameAutocompleteService } from './../../services/autocomplete/geoname-autocomplete.service';
import { KeywordAutocompleteService } from './../../services/autocomplete/keyword-autocomplete.service';
import { Field } from './../../model/field.model';
import { Component, OnInit, Input } from '@angular/core';
import { MatDialog } from '@angular/material';
import { EditCoordinatesDialogComponent } from 'app/dialogs/edit-coordinates-dialog/edit-coordinates-dialog.component';

@Component({
  selector: 'app-editor-keyword',
  templateUrl: './editor-keyword.component.html',
  styleUrls: ['./editor-keyword.component.css']
})
export class EditorKeywordComponent implements OnInit {
  @Input() field: Field;

  constructor(
    private keywordAutocomplete: KeywordAutocompleteService,
    private geonameAutocomplete: GeonameAutocompleteService,
    private dialog: MatDialog) { }

  ngOnInit() {
  }

  editCoordinates(item) {
    this.dialog.open(EditCoordinatesDialogComponent).afterClosed().subscribe(result => {
      console.log(result)
      if (result && (result.coordinates || result.coordinates == "") ) {
          item.setCoords(result.coordinates);
      }
    });
  }

}
