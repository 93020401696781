import { Field } from './../../model/field.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-relateditem',
  templateUrl: './editor-relateditem.component.html',
  styleUrls: ['./editor-relateditem.component.scss']
})
export class EditorsRelatedItemComponent implements OnInit {
  @Input() field: Field;

  types: string[] = ['', 'preceding', 'succeeding', 'original', 'host', 'constituent', 'series', 'otherVersion', 'otherFormat', 'isReferencedBy', 'references', 'reviewOf'];
  titleTypes: string[] = ['', 'abbreviated', 'translated', 'alternative', 'uniform'];

  constructor() {
  }

  ngOnInit() {
  }

}
