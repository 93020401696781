
export class PeriodicalItem {
  pid: string;
  title: string;
  subtitle: string;
  type: string;
  sortIndex = 0;
  sortIndex2 = 0;
  policy: string;

  constructor(item) {
    this.pid = item['pid'];
    this.policy = item['policy'];
    this.type = item['type'];
    this.title = item['title'];
    this.subtitle = item['subtitle'];
    const d = item['details'];
    if (d) {
      if (item['model'] == 'periodicalvolume') {
        this.type = 'volume';
        this.title = d['year'] || "";
        this.subtitle = d['volumeNumber'] || "";
        const date = new Date()
      } else if (item['model'] == 'periodicalitem') {
        this.type = 'issue';
        this.title = d['date'] || "";
        this.subtitle = d['issueNumber'] || d['partNumber'] || "";
      }
    }
    this.recalcIndices();
  }


  recalcIndices() {
    this.sortIndex = this.buildSortIndex(this.title);
    this.sortIndex2 = this.buildSortIndex(this.subtitle);
  }

  private buildSortIndex(input: string): number {
    if (!input) {
      return 0;
    }
    let str = "";
    const arr = input.split('.');
    for (let i = arr.length - 1; i >= 0; i--) {
      let s = arr[i].trim();
      if (s.length == 1) {
        s = "0" + s;
      }
      str += s;
    }
    return parseInt(str) || 0;
  }


  public static toVolumeJsonArray(items: PeriodicalItem[]): any[] {
    const result = [];
    for (const item of items) {
      result.push({
        uuid: item.pid,
        year: item.title,
        number: item.subtitle,
        policy: item.policy
      });
    }
    return result;
  }

  public static toIssueJsonArray(items: PeriodicalItem[]): any[] {
    const result = [];
    for (const item of items) {
      result.push({
        uuid: item.pid,
        date: item.title,
        number: item.subtitle,
        policy: item.policy
      });
    }
    return result;
  }



}
