interface JPage { pid: string, name: string, type: string }

export class Page {
  pid: string;
  originalName: string;
  originalType: string;
  // originalIndex: number;
  // index: number;
  name: string;
  type: string;
  refresh = 0;

  public static toJsonArray(pages: Page[]): JPage[] {
    const result: JPage[] = [];
    for (const page of pages) {
      result.push(page.toJPage());
    }
    return result;
  }

  constructor(pid: string, name: string, type: string) { //, index: number) {
    let n = '';
    if (name) {
      n = name.trim();
    }
    this.pid = pid;
    this.originalName = n;
    this.name = n;
    this.originalType = type;
    this.type = type;
    // this.originalIndex = index;
    // this.index = index;
  }

  changed(): boolean {
    return this.originalName !== this.name || this.originalType !== this.type;
  }

  toJPage(): JPage {
    return {
      pid: this.pid,
      name: this.name,
      type: this.type
    }
  }

}
