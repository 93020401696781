import { MatDialog } from '@angular/material';
import { EventEmitter } from '@angular/core';
import { Translator } from 'angular-translator';
import { DomSanitizer } from '@angular/platform-browser';
import { KrameriusService } from './../../../services/kramerius.service';
import { Component, OnInit, Input, Output } from '@angular/core';
import { Page } from 'app/model/page.model';
import { PageImageDialogComponent } from 'app/editor/editor-pages/page-image-dialog/page-image-dialog.component';

@Component({
  selector: 'app-editor-page',
  templateUrl: './editor-page.component.html',
  styleUrls: ['./editor-page.component.scss']
})
export class EditorPageComponent implements OnInit {
  @Input() movable: boolean;
  @Input() editable: boolean;
  @Input() removable: boolean;
  @Input() selectable: boolean;
  @Input() page: Page;
  @Input() selected: boolean;
  @Output() recalculateFromPage: EventEmitter<Page> = new EventEmitter();
  @Output() pageRemoved: EventEmitter<Page> = new EventEmitter();
  @Output() changePageImage: EventEmitter<Page> = new EventEmitter();
  @Output() pageMoved: EventEmitter<Page> = new EventEmitter();
  @Output() toggleSelection: EventEmitter<Page> = new EventEmitter();


  types_codes: string[] = [
    'FrontCover', 'TitlePage', 'NormalPage', 'BackCover', 'Spine'
  ];

  types;

  constructor(private krameriusService: KrameriusService,
              private _sanitizer: DomSanitizer,
              public translator: Translator,
              private dialog: MatDialog,
            ) {
              this.translateTypes();
              translator.languageChanged.subscribe(() => this.translateTypes());
  }

  ngOnInit() {
  }

  recalculatePages() {
    this.recalculateFromPage.emit(this.page);
  }

  selectionToggle() {
    this.toggleSelection.emit(this.page);
  }

  removePage() {
    this.pageRemoved.emit(this.page);
  }

  movePage() {
    this.pageMoved.emit(this.page);
  }

  changeImage() {
    this.changePageImage.emit(this.page);
  }

  getThumb() {
    const url = this.krameriusService.getThumbUrl(this.page.pid) + '?ref=' + this.page.refresh;
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  private translateTypes() {
    this.translator.waitForTranslation().then(() => {
      this.types = [];
      for (const code of this.types_codes) {
        this.types.push({code: code, name: this.translator.instant('page_type.' + code)});
      }
    });
  }

  showImage() {
    const options = {
      data: {'pid': this.page.pid}
    };
    const dialogRef = this.dialog.open(PageImageDialogComponent, options);
  }




}
