import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { parse, ErrorCode, toOutputFormat } from './coords-parser';

@Component({
  selector: 'app-edit-coordinates-dialog',
  templateUrl: './edit-coordinates-dialog.component.html',
  styleUrls: ['./edit-coordinates-dialog.component.css']
})
export class EditCoordinatesDialogComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<EditCoordinatesDialogComponent>) { }

  coordinates = "";
  parsingError;
  coordsBb;

  ngOnInit() {
    // console.log('ngOnInit, coordinates: ' + this.coordinates)
    this.validate();
  }

  save() {
    this.dialogRef.close({
      coordinates: this.coordsBb,
    });
  }

  validate() {
    //console.log("validating")
    //  E 150°50'00"--E 160°50'00"/N 49°30'00"--N 48°30'00"
    if (!this.coordinates) {
      // this.parsingError = "Can't be empty"; //TODO: translate
      this.coordsBb = "";
      this.parsingError = undefined;
    } else {
      const parsed = parse(this.coordinates)
      if (parsed.ok) {
        this.parsingError = undefined;
        this.coordsBb = toOutputFormat(parsed.value.coords);
      } else {
        let errorType;
        switch (parsed.error.code) {
          case ErrorCode.INVALID_SYNTAX: {
            errorType = "Invalid syntax"; //TODO: translate
            break;
          }
          case ErrorCode.INCORRECT_VALUE: {
            errorType = "Incorrect value"; //TODO: translate
            break;
          }
        }
        this.parsingError = parsed.error.message ? (errorType + ": " + parsed.error.message) : errorType;
      }
    }
  }

}
