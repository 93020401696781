import { LocationAutocompleteService } from './../../services/autocomplete/location-autocomplete.service';
import { Field } from 'app/model/field.model';
import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'app-editor-location',
  templateUrl: './editor-location.component.html',
  styleUrls: ['./editor-location.component.css']
})
export class EditorLocationComponent implements OnInit {
  @Input() field: Field;

  constructor(private locationAutocomplete: LocationAutocompleteService) { }

  ngOnInit() {
  }



}
