import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Language extends Item {

    public language;

    static getSelector() {
        return 'language';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }

    private init() {
        if (!this.modsElement['languageTerm']) {
            const attrs = {'type': 'code', 'authority': 'iso639-2b'};
            this.modsElement['languageTerm'] = [Utils.createTextElement('', attrs)];
        }
        this.language = this.modsElement['languageTerm'][0];
    }


    public toDC() {
        if (this.language['_']) {
            return Utils.dcEl('language', this.language['_']);
        } else {
            return '';
        }
    }

}
