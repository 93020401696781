import { OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { CollectionsService } from 'app/services/collections.service';
import { BackendService } from './../services/backend.service';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import { Collection } from './../model/collection.model';
import { NotFoundError } from './../common/errors/not-found-error';
import { AppError } from './../common/errors/app-error';
import { Observable } from 'rxjs/Observable';
import { KrameriusService } from './../services/kramerius.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewCollectionDialogComponent } from 'app/dialogs/new-collection-dialog/new-collection-dialog.component';

@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.scss']
})
export class CollectionsComponent implements OnInit, OnDestroy {

  subscription: Subscription;
  collections: Collection[];

  constructor(private krameriusService: KrameriusService,
    private dialog: MatDialog,
    private backendService: BackendService,
    private collectionsService: CollectionsService) { }

  ngOnInit() {
    this.subscription = this.collectionsService.getCollections().subscribe(
      result => {
        this.collections = result;
      }
    );
    this.collectionsService.fetchCollections();
  }

  ngOnDestroy() {
    if(this.subscription) {
      this.subscription.unsubscribe();
    }
  }

 createCollection() {
    const dialogRef = this.dialog.open(NewCollectionDialogComponent, {
      data: { }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result !== 'close' && result['pid']) {
        const collection = new Collection();
        collection.pid = result['pid'];
        collection.nameEn = result['nameEn'];
        collection.nameCs = result['nameCs'];
        collection.count = 0;
        this.collections.unshift(collection);
      }
    });
  }

  private removeCollection(collection: Collection) {
    collection.loading = true;
    this.backendService.deleteCollection(collection.pid)
    .subscribe(
      response => {
        this.collections.splice(this.collections.indexOf(collection), 1);
      },
      error => {
        collection.loading = false;
      }
    );
  }

  private editCollection(collection: Collection) {
    const dialogRef = this.dialog.open(NewCollectionDialogComponent, {
      data: { nameEn: collection.nameEn, nameCs: collection.nameCs, pid: collection.pid  }
    });

    dialogRef.afterClosed().subscribe(result=> {
      if (result && result !== 'close' && result['pid']) {
        collection.nameEn = result['nameEn'];
        collection.nameCs = result['nameCs'];
      }
    });
  }


}
