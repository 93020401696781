import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  error: string;
  uuid = '';

  constructor(private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.error = params.get('error');
    });
  }

  search(uuid: string) {
    if (uuid && uuid.length > 0) {
      this.router.navigate(['/documents', uuid]);
    }
  }

}
