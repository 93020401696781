import { Role } from './role.model';
import { Field } from './field.model';
import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Physical extends Item {

    public extent;
    public digitalOrigin;


    static getSelector() {
        return 'physicalDescription';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }


    private init() {
        if (!this.modsElement['extent']) {
            this.modsElement['extent'] = Utils.createEmptyField();
        }
        this.extent = this.modsElement['extent'][0];
        if (!this.modsElement['digitalOrigin']) {
            this.modsElement['digitalOrigin'] = Utils.createEmptyField();
        }
        this.digitalOrigin = this.modsElement['digitalOrigin'][0];
    }

    public toDC() {
        if (this.extent['_']) {
            return Utils.dcEl('format', this.extent['_']);
        } else {
            return '';
        }
    }

}
