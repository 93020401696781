import { Observable } from 'rxjs/Observable';
import { concat } from 'rxjs/observable/concat';
import { MatDialog, MatDialogRef, MatSnackBar } from '@angular/material';
import { Http } from '@angular/http';
import { BackendService } from './../../services/backend.service';
import { Page } from 'app/model/page.model';
import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';
import Helper from 'app/helper';
import { CreatePagesDialogComponent } from 'app/dialogs/ceate-pages-dialog/create-pages-dialog.component';
import { SimpleDialogData } from 'app/dialogs/simple-dialog/simple-dialog';
import { SimpleDialogComponent } from 'app/dialogs/simple-dialog/simple-dialog.component';
import { Connector } from 'app/utils/connector.model';
import { MoveDialogComponent } from 'app/dialogs/move-dialog/move-dialog.component';
import { Translator } from 'angular-translator';

declare var $: any;

@Component({
  selector: 'app-editor-pages',
  templateUrl: './editor-pages.component.html',
  styleUrls: ['./editor-pages.component.scss']
})
export class EditorPagesComponent implements OnInit {
  @Input() document: Document;
  source: any;
  dragEnabled = false;
  filesToUpload: Array<File> = [];
  sourceIndex: number;
  pageToChangeImage: Page;
  mMovablePages: boolean = null;

  multipleSelection: boolean;

  selectedPages: Page[] = [];

  constructor(private http: Http,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private translator: Translator,
    public backendService: BackendService) {
  }

  ngOnInit() {
    this.multipleSelection = false;
  }


  toggleMultipleSelection() {
    this.selectedPages = [];
    this.multipleSelection = !this.multipleSelection;
  }

  togglePageSelection(page: Page) {
    if (this.selectedPages.indexOf(page) >= 0) {
      this.selectedPages.splice(this.selectedPages.indexOf(page), 1);
    } else {
      this.selectedPages.push(page);
    }
  }

  isPageSelected(page: Page) {
    return this.multipleSelection && this.selectedPages.indexOf(page) >= 0;
  }

  movablePages(): boolean {
    if (this.mMovablePages != null) {
      return this.mMovablePages;
    }
    this.mMovablePages = this.document.isIssue() || this.document.isVolume();
    return this.mMovablePages;
  }

  recalculatePages(page: Page) {
    this.document.recalculatePages(page);
  }

  refreshThumbs() {
    for (const page of this.document.pages) {
      page.refresh += 1;
    }
  }

  removePage(page: Page) {
    this.document.removePage(page);
  }

  moveSelectedPages() {
    if (this.selectedPages.length == 0) {
      return;
    }
    if (this.document.anythingToSave()) {
      this.snackBar.open(
        String(this.translator.instant('common.save_required')),
        String(this.translator.instant('common.ok')),
        { duration: 2000 }
      );
      return;
    }
    const pids = [];
    for (const page of this.document.pages) {
      if (this.selectedPages.indexOf(page) >= 0) {
        pids.push(page.pid);
      }
    }
    const path = this.document.pidPath.split('/');
    const dialogRef = this.dialog.open(MoveDialogComponent, {
      data: { pids: pids, current: this.document.uuid, parent: path[path.length - 2], model: 'issue' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'close') {
        for (const page of this.selectedPages) {
          this.document.removePage(page, false);
        }
        this.selectedPages = [];
      }
    });
  }

  movePage(page: Page) {
    if (this.document.anythingToSave()) {
      this.snackBar.open(
        String(this.translator.instant('common.save_required')),
        String(this.translator.instant('common.ok')),
        { duration: 2000 }
      );
      return;
    }
    const path = this.document.pidPath.split('/');
    const dialogRef = this.dialog.open(MoveDialogComponent, {
      data: { pids: [page.pid], current: this.document.uuid, parent: path[path.length - 2], model: 'issue' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'close') {
        this.document.removePage(page, false);
      }
    });

  }

  addPages() {
    $('#multiple_file_upload').trigger('click');
  }

  addPagesPdf() {
    $('#pdf_file_upload').trigger('click');
  }

  addPagesFromServer() {
    const pid = this.document.pidPath.split('/').pop();
    window.open('https://admin.difmoe.eu/processes?action=schedule_import&pid=' + pid, '_blank');
  }

  removePages() {
    const data: SimpleDialogData = {
      title: 'common.warning',
      message: 'editor.pages.remove_pages_warn_message',
      btn2: {
        label: 'common.no',
        value: 'no',
        color: 'default'
      },
      btn1: {
        label: 'common.yes',
        value: 'yes',
        color: 'warn'
      }
    };
    const dialogRef = this.dialog.open(SimpleDialogComponent, { data: data });
    dialogRef.afterClosed().subscribe(result => {
      if (result === 'yes') {
        this.document.removeAllPages();
      }
    });
  }

  pdfUploadEnabled(): boolean {
    return !!Connector.JAVA_BACKEND_URL;
  }


  uploadPdf(fileInput: any) {
    const files = <Array<File>>fileInput.target.files;
    if (files.length != 1) {
      return;
    }
    const dialog: MatDialogRef<CreatePagesDialogComponent> = this.dialog.open(CreatePagesDialogComponent);
    this.backendService.uploadNewPdf(files[0]).subscribe(response => {
      console.log(response);
      const filename = response.filename;
      const pages = response.pages;

      const uuids = [];
      let index = this.document.pages.length;
      const newPages: Page[] = [];
      const empty = index === 0;
      for (let i = 0; i < pages; i++) {
        index += 1;
        const uuid = Helper.newUUID();
        const pageType = index === 1 ? 'TitlePage' : 'NormalPage';
        const pageNumber = empty ? index + '' : '';
        newPages.push(new Page(uuid, pageNumber, pageType));
        uuids.push(uuid);
      }

      const convertRequest = this.backendService.convertUploadedPdf(this.document.uuid, uuids, filename);
      const newPagesRequest = this.backendService.createPages(this.document.uuid, this.document.policy, newPages, true);

      //u stran z pdf (oproti stranám přímo z obrázků) je potřeba nejprve připravit obrázky a ocr a až potom provádět ingest stránek
      //protože foxml se odkazuje přes http url na ocr data a ty datastreamy jsou managed, tedy v okamžiku sklízení do Fedory musí být ocr data dostupná
      //proto zde concat a ne forkJoin
      concat(convertRequest, newPagesRequest).subscribe(
        results => {
          if (results.url.startsWith(Connector.EDITOR_BACKEND_URL)) {
            for (const page of newPages) {
              this.document.pages.push(page);
            }
            dialog.close();
          }
        },
        error => {
          console.log('error', error);
          dialog.componentInstance.onError();
        }
      );

    }, error => {
      console.log('error', error);
      dialog.componentInstance.onError();
    });
  }

  uploadPages(fileInput: any) {
    const files = <Array<File>>fileInput.target.files;
    if (files.length < 1) {
      return;
    }
    const dialog: MatDialogRef<CreatePagesDialogComponent> = this.dialog.open(CreatePagesDialogComponent);

    const uuids = [];
    let index = this.document.pages.length;
    const newPages: Page[] = [];
    const empty = index === 0;
    for (let i = 0; i < files.length; i++) {
      index += 1;
      const uuid = Helper.newUUID();
      const pageType = index === 1 ? 'TitlePage' : 'NormalPage';
      const pageNumber = empty ? index + '' : '';
      newPages.push(new Page(uuid, pageNumber, pageType));
      uuids.push(uuid);
    }

    const uploadRequest = this.backendService.uploadNewImages(this.document.uuid, uuids, files) ;
    const newPagesRequest = this.backendService.createPages(this.document.uuid, this.document.policy, newPages, false);
    Observable.forkJoin([uploadRequest, newPagesRequest]).subscribe(
      results => {
        for (const page of newPages) {
          this.document.pages.push(page);
        }
        dialog.close();
      },
      error => {
        console.log('error', error);
        dialog.componentInstance.onError();
      }
    );

  }


  // changePageImage(page: Page) {
  //   this.pageToChangeImage = page;
  //   $('#single_file_upload').trigger('click');
  // }

  // onChangePageImage(fileInput: any) {
  //   const files = <Array<File>>fileInput.target.files;
  //   this.backendService.changePageImage(this.document.uuid, this.pageToChangeImage.pid, files).subscribe(
  //     result => {
  //       this.pageToChangeImage.refresh += 1;
  //     },
  //     error => console.log('File Change Error', error)
  //   );
  // }


  // Drag & Drop //

  isbefore(a, b) {
    if (a.parentNode === b.parentNode) {
      for (let cur = a; cur; cur = cur.previousSibling) {
        if (cur === b) {
          return true;
        }
      }
    }
    return false;
  }

  dragenter($event) {
    if (this.source.parentNode !== $event.currentTarget.parentNode) {
      return;
    }
    const target = $event.currentTarget;
    if (this.isbefore(this.source, target)) {
      target.parentNode.insertBefore(this.source, target); // insert before
    } else {
      target.parentNode.insertBefore(this.source, target.nextSibling); // insert after
    }
  }

  dragend($event) {
    const targetIndex = this.getIndex(this.source);
    const from = this.sourceIndex - 1;
    const to = targetIndex - 1;
    if (from !== to) {
      this.document.reorderPages(from, to);
    }
  }

  dragstart($event) {
    if (!this.dragEnabled) {
      return;
    }
    this.source = $event.currentTarget;
    this.sourceIndex = this.getIndex(this.source);
    $event.dataTransfer.effectAllowed = 'move';
  }

  mousedown($event) {
    if ($event.target.classList.contains('page-drag-handle') > 0) {
      this.dragEnabled = true;
    } else {
      $event.preventDefault();
      this.dragEnabled = false;
    }
  }

  private getIndex(el) {
    return Array.prototype.indexOf.call(el.parentNode.childNodes, el);
  }



}
