import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Title extends Item {

    public nonSort;
    public title;
    public subTitle;
    public partNumber;
    public partName;

    static getSelector() {
        return 'titleInfo';
    }

    constructor(modsElement) {
        super(modsElement, ['type']);
        this.init();
    }


    public nonSortToggleDisabled(): boolean {
        return !this.nonSort['_'] && !this.title['_'];
    }

    public onNonSotToggleChanged(event) {
        if (event.checked) {
            const str = this.title['_'];
            const wordArray = str.split(' ');
            this.nonSort['_'] = wordArray[0];
            wordArray.splice(0, 1);
            this.title['_'] = wordArray.join(' ').trim();
        } else {
            this.title['_'] = this.nonSort['_'] + ' ' + this.title['_'];
            this.nonSort['_'] = '';
        }
    }



    private init() {
        if (!this.modsElement['nonSort']) {
            this.modsElement['nonSort'] = Utils.createEmptyField();
        }
        if (!this.modsElement['title']) {
            this.modsElement['title'] = Utils.createEmptyField();
        }
        if (!this.modsElement['subTitle']) {
            this.modsElement['subTitle'] = Utils.createEmptyField();
        }
        if (!this.modsElement['partNumber']) {
            this.modsElement['partNumber'] = Utils.createEmptyField();
        }
        if (!this.modsElement['partName']) {
            this.modsElement['partName'] = Utils.createEmptyField();
        }
        this.nonSort = this.modsElement['nonSort'][0];
        this.title = this.modsElement['title'][0];
        this.subTitle = this.modsElement['subTitle'][0];
        this.partNumber = this.modsElement['partNumber'][0];
        this.partName = this.modsElement['partName'][0];
    }

    public getFullTitle() {
        let text = '';
        if (this.nonSort['_']) {
            text += this.nonSort['_'];
        }
        if (this.title['_']) {
            if (text !== '') {
                text += ' ';
            }
            text += this.title['_'];
        }
        if (this.subTitle['_']) {
            if (text !== '') {
              text += ': ';
            }
            text += this.subTitle['_'];
        }
        if (this.partNumber['_']) {
            if (text !== '') {
              text += ': ';
            }
            text += this.partNumber['_'];
        }
        if (this.partName['_']) {
            if (text !== '') {
              text += ': ';
            }
            text += this.partName['_'];
        }
        return text;
    }

    public toDC() {
        const fullTitle = this.getFullTitle();
        if (fullTitle === '') {
            return '';
        }
        return Utils.dcEl('title', fullTitle);
    }

}
