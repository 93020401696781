import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-create-pages-dialog',
  templateUrl: './create-pages-dialog.component.html',
  styleUrls: ['./create-pages-dialog.component.scss']
})
export class CreatePagesDialogComponent implements OnInit {

  state = 0;

  constructor() {
  }

  ngOnInit() {
  }

  onError() {
    this.state = 1;
  }

}
