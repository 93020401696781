import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';
import { BackendService } from 'app/services/backend.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { NewVolumeDialogComponent } from 'app/dialogs/new-volume-dialog/new-volume-dialog.component';
import { KrameriusService } from 'app/services/kramerius.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { RemoveVolumeDialogComponent } from 'app/dialogs/remove-volume-dialog/remove-volume-dialog.component';
import { PeriodicalItem } from 'app/model/periodicalitem.model';
import { MoveDialogComponent } from 'app/dialogs/move-dialog/move-dialog.component';
import { Translator } from 'angular-translator';
import { RenumberIssuesDialogComponent } from 'app/dialogs/renumber-issues-dialog/renumber-issues-dialog.component';

@Component({
  selector: 'app-editor-children',
  templateUrl: './editor-children.component.html',
  styleUrls: ['./editor-children.component.scss']
})
export class EditorChildrenComponent implements OnInit {
  @Input() items: PeriodicalItem[];
  @Input() document: Document;
  @Input() model: string;

  showThumb = false;
  selectedItems: PeriodicalItem[];

  constructor(public backendService: BackendService, 
              private krameriusService: KrameriusService,
              private router: Router,
              private snackBar: MatSnackBar,
              private translator: Translator,
              private _sanitizer: DomSanitizer,
              private dialog: MatDialog) {}

  ngOnInit() {
    this.selectedItems = [];
    this.showThumb = false;    
  }

  add() {
    this.openNewDialogForModel();
  }

  private openNewDialogForModel() {
    const dialogRef = this.dialog.open(NewVolumeDialogComponent, {
      data: { parentPid: this.document.uuid, policy: this.document.policy, model: this.model }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.item) {
        const item = new PeriodicalItem(result.item);
        item.recalcIndices();
        this.items.push(item);
        this.document.sortPeriodicalItems(this.items);
        this.selectedItems = [];
        this.selectedItems.push(item);
      }
    });
  }

  private openEditDialogForModel() {
    const dialogRef = this.dialog.open(NewVolumeDialogComponent, {
      data: { item: this.selectedItems[0], model: this.model }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.item) {
        this.selectedItems[0].title = result.item.title;
        this.selectedItems[0].subtitle = result.item.subtitle;
        this.selectedItems[0].recalcIndices();
        this.document.sortPeriodicalItems(this.items);
      }
    });
  }

  getThumb(item) {
    const url = this.krameriusService.getThumbUrl(item.pid);
    return this._sanitizer.bypassSecurityTrustStyle(`url(${url})`);
  }

  onItemClick(item, event) {
    const m = this.model == 'issue' && (event && (event.metaKey || event.ctrlKey));
    if (this.selectedItems.indexOf(item) >= 0) {
      if (m) {
        this.selectedItems.splice(this.selectedItems.indexOf(item), 1);
      } else {
        this.selectedItems = [];
      }
    } else {
      if (!m) {
        this.selectedItems = [];
      }
      this.selectedItems.push(item);
    }
  }

  onItemDblClick(item) {
    this.router.navigate(['/documents', item.pid]);
  }

  onOpen() {
    this.router.navigate(['/documents', this.selectedItems[0].pid]);
  }

  onEdit() {
    this.openEditDialogForModel();
  }

  onMove() {
    if (this.document.anythingToSave()) {
      this.snackBar.open(
        String(this.translator.instant('common.save_required')), 
        String(this.translator.instant('common.ok')), 
        {duration: 2000}
      );
      return;
    }
    const path = this.document.pidPath.split('/');
    const pids = this.selectedItems.map((p: PeriodicalItem) =>  p.pid );
    const dialogRef = this.dialog.open(MoveDialogComponent, {
      data: { pids: pids, current: this.document.uuid, parent: path[path.length - 2], model: 'volume' }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'close') {
        for (const item of this.selectedItems) {
          this.items.splice(this.items.indexOf(item), 1);
        }
        this.selectedItems = [];
      }
    });

  }

  onRemove() {
    const dialogRef = this.dialog.open(RemoveVolumeDialogComponent, {
      data: { uuid: this.selectedItems[0].pid, title: this.selectedItems[0].title, model: this.model }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result.success) {
        this.items.splice(this.items.indexOf(this.selectedItems[0]), 1);
        this.selectedItems = [];
      }
    });
  }

  onRenumber() {
    const number = parseInt(this.selectedItems[0].subtitle);
    const dialogRef = this.dialog.open(RenumberIssuesDialogComponent, {
      data: { number:  number, model: this.model }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result && result["number"]) {
        let n = result["number"];
        if (this.model == 'volume') {
          this.document.renumberVolumes(this.selectedItems[0], n);
        } else if (this.model == 'issue') {
          this.document.renumberIssues(this.selectedItems[0], n);
        }
      }
    });
  }


}
