import { LocationAutocompleteService } from './../../services/autocomplete/location-autocomplete.service';
import { Field } from 'app/model/field.model';
import { Document } from './../../model/document.model';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-editor-physical',
  templateUrl: './editor-physical.component.html',
  styleUrls: ['./editor-physical.component.scss']
})
export class EditorPhysicalComponent implements OnInit {
  @Input() field: Field;

  originTypes: string[] = ['', 'born digital', 'reformatted digital', 'digitized microfilm', 'Reformatted Digital', 'digitized other analog'];

  constructor() { }

  ngOnInit() {
  }

}
