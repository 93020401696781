export class Connector {

  // vefuco
  // public static KRAMERIUS_URL = 'http://kramerius.digibib.genealogy.net';
  // public static UPLOAD_URL = 'http://195.201.83.34:3003/upload';
  // production
  // public static EDITOR_URL = 'https://api.digitalniknihovna.cz/editor';
  // public static FRONTEND_LINK = 'http://kramerius.digibib.genealogy.net/v/';
  // local
  // public static EDITOR_URL = 'http://localhost:3000/editor';
  // public static FRONTEND_LINK = 'http://127.0.0.1:8080/#!/vefuco/';


  // difmoe
  public static INSTANCE = 'difmoe';
  public static KRAMERIUS_URL = 'https://kramerius.difmoe.eu';
  public static ADD_PERSISTENT_LINK = true;
  public static PERSISTENT_LINK = 'https://www.difmoe.eu/d/uuid/';
  // production
  public static FRONTEND_LINK = 'https://www.difmoe.eu/d/';
  public static EDITOR_BACKEND_URL = 'https://difmoe.rychtar.cloud/editor';
  public static IMAGE_UPLOAD_BACKEND_URL = 'http://www.difmoe.eu:3003';
  public static JAVA_BACKEND_URL = 'https://backend.editor.difmoe.eu';
  // local
  // public static FRONTEND_LINK = 'http://127.0.0.1:8080/#!/d/';
  // public static EDITOR_BACKEND_URL = 'http://localhost:3000/editor';
  // public static IMAGE_UPLOAD_BACKEND_URL = 'http://localhost:3003';
  // public static JAVA_BACKEND_URL = 'http://localhost:8080/editor-backend';


  // kfbz
  // public static INSTANCE = 'kfbz';
  // public static KRAMERIUS_URL = 'http://dlib.kfbz.cz';
  // public static ADD_PERSISTENT_LINK = false;
  // public static PERSISTENT_LINK = 'http://www.digitalniknihovna.cz/kfbz/uuid/';
  // // production
  // public static FRONTEND_LINK = 'http://www.digitalniknihovna.cz/kfbz/';
  // public static EDITOR_BACKEND_URL = 'http://kfbz.rychtar.cloud/editor';
  // public static IMAGE_UPLOAD_BACKEND_URL = 'http://upload.kfbz.rychtar.cloud';//TODO: opravdu, tohle je nasazene lokalne na jejich serveru
  // public static JAVA_BACKEND_URL = ''; 
  // // local
  // public static FRONTEND_LINK = 'http://127.0.0.1:8080/#!/kfbz/';
  // public static EDITOR_BACKEND_URL = 'http://localhost:3000/editor';
  // public static IMAGE_UPLOAD_BACKEND_URL = 'http://localhost:3003';
  // public static JAVA_BACKEND_URL = 'http://localhost:8080/editor-backend';



  static getFrontendLink(path: string, uuid: string): string {
    return Connector.FRONTEND_LINK + (path ? path + '/' : '') + uuid;
  }

  static getPersistentLink(uuid: string): string {
    return Connector.PERSISTENT_LINK + uuid;
  }


}
