import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Keyword extends Item {

    public topics;
    public geographics;
    public coordinates;


    static getSelector() {
        return 'subject';
    }

    constructor(modsElement) {
        super(modsElement);
        this.init();
    }


    private init() {
        if (!this.modsElement['topic']) {
            this.modsElement['topic'] = Utils.createEmptyField();
        }
        if (!this.modsElement['geographic']) {
            this.modsElement['geographic'] = Utils.createEmptyField();
        }
        this.topics = this.modsElement['topic'];
        this.geographics = this.modsElement['geographic'];

        if (!this.modsElement['cartographics']) {
            this.modsElement['cartographics'] = [];
        }
        for (let cartographics of this.modsElement['cartographics']) {
            if (cartographics['coordinates'] && cartographics['coordinates'][0]) {
                this.coordinates = cartographics['coordinates'][0];
            }
        }
        if (!this.coordinates) {
            const attrs = {};
            const coordinates = Utils.createObjWithTextElement('coordinates', '', attrs);
            console.log(coordinates)
            this.coordinates = coordinates['coordinates'][0];
            this.modsElement['cartographics'].push(coordinates);
        }
    }

    public toDC() {
        let s = '';
        for (const t of this.topics) {
            if (t['_']) {
                s += Utils.dcEl('subject', t['_']);
            }
        }
        for (const g of this.geographics) {
            if (g['_']) {
                s += Utils.dcEl('coverage', g['_']);
            }
        }
        return s;
    }

    public addTopic() {
        console.log(JSON.parse(JSON.stringify(this.topics)));
        this.topics.push({ '_': '' });
        console.log(JSON.parse(JSON.stringify(this.topics)));
    }

    public addGeographic() {
        this.geographics.push({ '_': '' });
    }

    public removeTopic(index: number) {
        console.log(JSON.parse(JSON.stringify(this.topics)));
        this.topics.splice(index, 1);
        console.log(JSON.parse(JSON.stringify(this.topics)));
    }

    public removeGeographic(index: number) {
        this.geographics.splice(index, 1);
    }

    public setCoords(coords: string) {
        this.coordinates["_"] = coords;
    }
}


