import Utils from 'app/utils';
import { Item } from 'app/model/item.model';

export class Publisher extends Item {

    public publisher;
    public edition;
    public place;
    public dateIssued;
    public dateFrom;
    public dateTo;
    public qualifiers: string[] = ['', 'approximate', 'inferred', 'questionable'];

    static getSelector() {
        return 'originInfo';
    }

    constructor(modsElement) {
        super(modsElement, ['eventType']);
        this.init();
    }

    private init() {
        if (!this.modsElement['publisher']) {
            this.modsElement['publisher'] = Utils.createEmptyField();
        }
        if (!this.modsElement['edition']) {
            this.modsElement['edition'] = Utils.createEmptyField();
        }
        if (!this.modsElement['dateIssued']) {
            this.modsElement['dateIssued'] = [];
        }
        const dates = this.modsElement['dateIssued'];
        for (const date of dates) {
            if (Utils.hasAttributeWithValue(date, 'point', 'start')) {
                this.dateFrom = date;
            }  else if (Utils.hasAttributeWithValue(date, 'point', 'end')) {
                this.dateTo = date;
            }  else {
                this.dateIssued = date;
            }
        }
        if (this.dateFrom == null) {
            this.dateFrom = Utils.createTextElement('', {'point': 'start'});
            dates.push(this.dateFrom);
        }
        if (this.dateTo == null) {
            this.dateTo = Utils.createTextElement('', {'point': 'end'});
            dates.push(this.dateTo);
        }
        if (this.dateIssued == null) {
            let dateText = '';
            let qualifier = '';
            if (this.dateFrom['_'] && this.dateFrom['_']) {
                dateText = this.dateFrom['_'] + '-' + this.dateTo['_'];
                if (this.dateFrom['$']['qualifier']) {
                    qualifier = this.dateFrom['$']['qualifier'];
                } else if (this.dateTo['$']['qualifier']) {
                    qualifier = this.dateTo['$']['qualifier'];
                }
            }
            this.dateIssued = Utils.createTextElement(dateText, {qualifier: qualifier, encoding: 'w3cdtf'});
            dates.push(this.dateIssued);
        }
        if (!this.dateIssued['$']) {
            this.dateIssued['$'] = {
                qualifier: '',
                encoding: 'w3cdtf'
            };
        }
        if (!this.dateIssued['$']['qualifier']) {
            this.dateIssued['$']['qualifier'] = '';
        }
        if (!this.dateIssued['$']['encoding']) {
            this.dateIssued['$']['encoding'] = 'w3cdtf';
        }
        if (this.dateFrom && !this.dateFrom['$']['encoding']) {
            this.dateFrom['$']['encoding'] = 'w3cdtf';
        }
        if (this.dateTo && !this.dateTo['$']['encoding']) {
            this.dateTo['$']['encoding'] = 'w3cdtf';
        }
        const dindex = dates.indexOf(this.dateIssued);
        if (dindex > 0) {
            dates.splice(dindex, 1);
            dates.unshift(this.dateIssued);
        }

        // console.log('pic', dates.indexOf(this.dateIssued));

        for (const date of dates) {
            if (Utils.hasAttributeWithValue(date, 'point', 'start')) {
                this.dateFrom = date;
            }  else if (Utils.hasAttributeWithValue(date, 'point', 'end')) {
                this.dateTo = date;
            }  else {
                this.dateIssued = date;
            }
        }



        if (!this.modsElement['place']) {
            this.modsElement['place'] = [];
        }
        this.publisher = this.modsElement['publisher'][0];
        this.edition = this.modsElement['edition'][0];
        const ctx = this;
        this.modsElement['place'].forEach(function(place) {
            if (place['placeTerm'] &&
                place['placeTerm'][0] &&
                place['placeTerm'][0]['$'] &&
                place['placeTerm'][0]['$']['type'] === 'text') {
                    ctx.place = place['placeTerm'][0];
                }
        });
        if (!this.place) {
            const attrs = {'type': 'text'};
            const place = Utils.createObjWithTextElement('placeTerm', '', attrs);
            this.place = place['placeTerm'][0];
            this.modsElement['place'].push(place);
        }
    }

    public update() {
        const dateParts = this.dateIssued['_'].split('-');
        if (dateParts.length === 2 && dateParts[0] && dateParts[1]) {
            this.dateFrom['_'] = dateParts[0];
            this.dateTo['_'] = dateParts[1];
            const qualifier = this.dateIssued['$']['qualifier'];
            this.dateFrom['$']['qualifier'] = qualifier;
            this.dateTo['$']['qualifier'] = qualifier;
            // this.dateIssued['_'] = '';
        } else {
            this.dateFrom['_'] = '';
            this.dateTo['_'] = '';
        }
    }

    public toDC() {
        let dc = '';
        if (this.publisher['_']) {
            dc += Utils.dcEl('publisher', this.publisher['_']);
        }
        if (this.dateIssued['_']) {
            dc += Utils.dcEl('date', this.dateIssued['_']);
        }
        return dc;
    }

}
