import { Router } from '@angular/router';
import { Translator } from 'angular-translator';
import { BackendService } from './../../services/backend.service';
import { Component, OnInit } from '@angular/core';
import Helper from 'app/helper';

@Component({
  selector: 'app-new-draft',
  templateUrl: './new-draft.component.html',
  styleUrls: ['./new-draft.component.scss']
})
export class NewDraftComponent implements OnInit {

  model_codes = ['graphic', 'monograph', 'map', 'archive', 'manuscript', 'periodical'];
  models;
  error = false;
  inProgress = false;
  title: string;
  model: string;
  pid: string;
  localePath: string;
  policy = 'policy:public';

  constructor(private backendService: BackendService,
              private router: Router,
              public translator: Translator) {
    this.model = 'graphic';
    this.pid = Helper.newUUID();
    this.translateModels();
    translator.languageChanged.subscribe(() => this.translateModels());
  }

  ngOnInit() {
  }


  createDocument() {
    this.inProgress = true;
    this.error = false;

    this.backendService.createDocument(this.pid, this.policy, this.title, this.model)
    .subscribe(
      response => {
        this.inProgress = false;
        this.router.navigate(['/documents', this.pid]);
      },
      error => {
        this.inProgress = false;
        this.error = true;
      }
    );
  }


  private translateModels() {
    this.translator.waitForTranslation().then(() => {
      this.models = [];
      for (const code of this.model_codes) {
        this.models.push({code: code, name: this.translator.instant('model.' + code)});
      }
    });
  }

}
