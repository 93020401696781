import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-not-logged-in-dialog',
  templateUrl: './not-logged-in-dialog.component.html',
  styleUrls: ['./not-logged-in-dialog.component.scss']
})
export class NotLoggedInDialogComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

  change(event) {
    if (event.checked) {
      localStorage.setItem('dnsawd', 'yes');
    } else {
      localStorage.removeItem('dnsawd');
    }
  }


}
