import { Document } from './../model/document.model';
import { Observable } from 'rxjs/Observable';
import { KrameriusService } from './kramerius.service';
import { Injectable } from '@angular/core';
import { BackendService } from './backend.service';

@Injectable()
export class UpdaterService {

  documents: [{'PID': string, 'dc.title': string}];
  index = -1;

  constructor(private krameriusService: KrameriusService, private backendService: BackendService) { }

  run() {
    this.krameriusService.getSolrResult('fl=PID,dc.title&q=fedora.model:monograph OR fedora.model:periodical OR fedora.model:map OR fedora.model:graphic OR fedora.model:archive OR fedora.model:manuscript&rows=2000').subscribe(
      result => {
        this.documents = result['response']['docs'];
        this.index = -1;
        this.next();
      }
    )
  }

  next() {
    this.index += 1;
    if (this.documents.length > this.index) {
      const uuid = this.documents[this.index].PID;
      const title = this.documents[this.index]['dc.title'];
      console.log(this.index + ': ' + uuid + ' (' + title + ')');
      this.loadDocument(uuid);
    }
  }

  loadDocument(uuid: string) {
    const modsRequest = this.krameriusService.getMods(uuid);
    const dcRequest = this.krameriusService.getDc(uuid);

    Observable.forkJoin([modsRequest, dcRequest]).subscribe(
      results => {
        const mods = results[0];
        const dc = results[1];
        const document = new Document(uuid, mods, dc, [], [], "");
        this.onDocumentLoaded(document);
      },
      () => {
        console.error('loading document failed');
      }
    );
  }

  private onDocumentLoaded(document: Document) {
    if (!document.enhanced) {
      this.next();
      return;
    }
    this.backendService.updateDocument(document).subscribe(
      result => {
        this.next();
      },
      error => {
        console.error('loading document failed');
        this.next();
      }
    );
  }

}
