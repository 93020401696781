import { NgModule } from '@angular/core';
import { MatCheckboxModule, MatToolbarModule, MatIconModule, MatInputModule, MatMenuModule, MatTabsModule, MatChipsModule, MatSnackBarModule} from '@angular/material';
import { MatTooltipModule, MatSelectModule, MatButtonModule, MatSlideToggleModule, MatDialogModule} from '@angular/material';
import { MatProgressSpinnerModule, MatAutocompleteModule, MatFormFieldModule, MatRadioModule } from '@angular/material';

@NgModule({
  exports: [
    MatCheckboxModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatInputModule,
    MatTabsModule,
    MatTooltipModule,
    MatSelectModule,
    MatButtonModule,
    MatSlideToggleModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatChipsModule,
    MatRadioModule,
    MatSnackBarModule
  ]
})
export class MatComponentsModule { }
